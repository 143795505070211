import { Predicate, FilterQueryOp } from 'breeze-client';
import { FluentRuleCustomizer } from 'aurelia-validation';
import { Router } from 'aurelia-router';
import { EntityDetailViewModelBase, CustomLogger, EditingModeEnum, ServiceBase, TranslatedPropertyRules, EnumerationTypeService, Various } from 'digiwall-lib';
import { Zeus } from "../generated";
import { autoinject, computedFrom, Container } from 'aurelia-framework';
import * as Constants from '../constants';
import { Utils } from 'utils/utils';
import { ZeusUtilsMlFieldHelper } from 'utils/utils-ml-field-helper';
import { AppModuleService } from 'app-modules/app-module-service';
import { AppModuleEnum } from 'app-modules/constants';
import * as MSConstants from '../app-modules/mobile-screens/constants';

@autoinject
export abstract class AlgorithmDetail<A extends Zeus.Web.Model.InputPickingAlgorithm, P extends Zeus.Web.Model.InputPickingAlgorithmPart>
  extends EntityDetailViewModelBase<A> {

  private partService: ServiceBase<P>;
  private goalService: EnumerationTypeService;

  constructor(public ressourceName: string, protected partRessourceName, private typeId: number, private goalCategory: number, private appModuleService: AppModuleService) {
    super(Container.instance.get(Router), Container.instance.get(CustomLogger));
    super.initialize(new ServiceBase<A>(ressourceName));
    this.partService = new ServiceBase<P>(partRessourceName);

    this.goalService = new EnumerationTypeService(goalCategory);
    this.goalService.gridDataSource.customSelect2Predicates = () => {
      let predicates = this.entity.parts.filter(x => x.goalId).map(x => new Predicate('id', FilterQueryOp.NotEquals, x.goalId));
      if (!this.isInput || !appModuleService.isActive(AppModuleEnum.MobileScreens)) {
        predicates.push(new Predicate('id', FilterQueryOp.NotEquals, MSConstants.SortingLocation.PrioritiseArticleBulkVolume));
      }
      return predicates.length == 0 ? null : Predicate.and(...predicates);
    }
  }

  @computedFrom('typeId')
  private get isInput(): boolean {
    return this.typeId == Constants.WorkOrderType.Input;
  }
  @computedFrom('isInput')
  private get typeString(): string {
    return this.isInput ? 'input' : 'picking';
  }

  public async activate(params) {
    const id = params.param1;
    if (id == Various.NewId) {
      this.editingMode = EditingModeEnum.Create;
      this.entity = await this.service.createEntity();
      this.entity.typeId = this.typeId;
    } else {
      this.editingMode = EditingModeEnum.Update;
      this.entity = await this.service.getEntityById(id, 'parts.goal');
      this.entity.parts?.sort((a, b) => a.order - b.order);
    }

    this.getValidationRules().on(this.entity);
    this.controller.addObject(this.entity);
  }

  protected getValidationRules(): FluentRuleCustomizer<unknown, any> {
    return TranslatedPropertyRules.anyRequired('name')
      .ensure('parts')
      .satisfies((parts: Zeus.Web.Model.InputAlgorithmPart[]) => parts?.length > 0)
      .withMessage(`algorithm.${this.typeString}.partValidation.length`)
      .then()
      .satisfies((parts: Zeus.Web.Model.InputPickingAlgorithmPart[]) => null == parts.find(part => null == part.goal))
      .withMessage(`algorithm.${this.typeString}.partValidation.goal`)
  }

  @computedFrom('editingMode', 'entity.name')
  public get documentTitle() {
    if (this.editingMode === EditingModeEnum.Create) {
      return this.i18n.tr("algorithm.algorithm");
    } else {
      return this.entity?.name._translation;
    }
  }

  protected async addPart(): Promise<P> {
    let part = await this.partService.createEntity({ algorithmId: this.entity.id } as any);
    part.order = this.entity.parts?.length;
    return part;
  }

  private removePart(part: Zeus.Web.Model.InputPickingAlgorithmPart) {
    Utils.detachOrDeleteEntity(part);
    this.controller.removeObject(part);
    this.resetPartsOrder();
  }

  private movePart(index: number, delta: number) {
    let newIndex = Math.max(0, Math.min(this.entity.parts.length - 1, index + delta));
    if (newIndex == index) {
      return;
    }
    let part = this.entity.parts.splice(index, 1);
    this.entity.parts.splice(newIndex, 0, ...part);
    this.resetPartsOrder();
  }

  private resetPartsOrder() {
    this.entity.parts.forEach((part, index) => part.order = index + 1);
  }

  public async beforeSave() {
    return await ZeusUtilsMlFieldHelper.BeforeSave(this.entity, [
      { prop: this.entity.name, label: "algorithm.name", isRequired: true }
    ]);
  }
}
