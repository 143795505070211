import { HttpClient } from 'aurelia-fetch-client';
import * as LPConstants from '../constants';
import { LabelPrinting } from '../generated';
import { autoinject } from "aurelia-framework";

@autoinject
export class ApiService {

  constructor(private http: HttpClient) {

  }

  public printLabel(printLabelDTO: LabelPrinting.Model.PrintLabelDTO): Promise<Response> | null {
    if (printLabelDTO == null) {
      return;
    }
    return this.http.post(LPConstants.Application.PrintLabelsFromDTO, JSON.stringify(printLabelDTO))
  }

  public getLabelPrintConfigurationByWorkstationId(id: number): Promise<Response> | null {
    if (id == null || isNaN(id)) {
      return;
    }
    return this.http.get(`${LPConstants.Application.GetLabelPrintConfigurationByWorkstationId}?id=${id}`)
  }
}
