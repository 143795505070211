
export class AppModule {
  public static DropToLight = "DropToLight";
  public static AutoLaunch = "AutoLaunch";
  public static MultipleBay = "MultipleBay";
  public static LabelPrinting = "LabelPrinting";
  public static StandardTrayTemplates = "StandardTrayTemplates";
  public static RestrictedTrays = "RestrictedTrays";
  public static BadgeLogin = "BadgeLogin";
  public static OrderScanning = "OrderScanning";
  public static GraphicalLedBar = "GraphicalLedBar";
  public static Mirroring = "Mirroring";
  public static MobileScreens = "MobileScreens";
  public static CartReception = "CartReception";
  public static StaticLikeAModula = "StaticLikeAModula";
  public static CheckScanning = "CheckScanning";
  public static Conveyor = "Conveyor";
  public static Borrowing = "Borrowing";
  public static FreePicking = "FreePicking";
  public static ReportsAndKpi = "Reports & Kpi";
}

export enum AppModuleEnum {
  DropToLight = 1,
  AutoLaunch = 2,
  MultipleBay = 3,
  LabelPrinting = 4,
  StandardTrayTemplates = 5,
  RestrictedTrays = 6,
  BadgeLogin = 7,
  OrderScanning = 8,
  GraphicalLedBar = 9,
  Mirroring = 10,
  MobileScreens = 11,
  CartReception = 12,
  StaticLikeAModula = 13,
  CheckScanning = 14,
  Conveyor = 15,
  Borrowing = 16,
  FreePicking = 17,
  ReportsAndKpi = 18
}

