import { HttpClient } from 'aurelia-fetch-client';
import { BindingEngine, autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { CustomLogger, DisposableViewModelBase, EnumerationType, EnumerationTypeService, ServiceBase, UIInternal, } from 'digiwall-lib';
import { Zeus } from 'generated';
import * as Constants from '../../../../constants';
import { FilterQueryOp, Predicate } from 'breeze-client';

@autoinject
export abstract class BaseReport<T extends ReportParameters> extends DisposableViewModelBase {
  private previewUrl: string;
  private isLoading: boolean;
  protected woStatus: Array<EnumerationType>;

  protected abstract urlReporting: string;

  protected isFetchingParams: boolean = false;
  protected workOrderService: ServiceBase<Zeus.Web.Model.WorkOrder>;
  protected parameters: T;

  constructor(private logger: CustomLogger, private http: HttpClient, private i18n: I18N, protected bindingEngine: BindingEngine) {
    super();
    this.workOrderService = new ServiceBase<Zeus.Web.Model.WorkOrder>(Constants.EntityTypeNames.WorkOrder);
  }

  public async activate(params) {
    this.woStatus = await (new EnumerationTypeService(Constants.EnumerationTypes.WorkOrderStatus)).getEntities(this.getWoStatusPredicate("id"));
    this.workOrderService.gridDataSource.customSelect2Predicates = () => this.getWoPredicate();
  }

  protected getWoStatusPredicate(property: string): Predicate {
    return Predicate.or([
      new Predicate(property, FilterQueryOp.Equals, Constants.WorkOrderStatus.Finalised),
      new Predicate(property, FilterQueryOp.Equals, Constants.WorkOrderStatus.FinalisedIncomplete),
      new Predicate(property, FilterQueryOp.Equals, Constants.WorkOrderStatus.PartiallyProcessed),
      new Predicate(property, FilterQueryOp.Equals, Constants.WorkOrderStatus.Cancelled)
    ]);
  }

  protected getWoPredicate(): Predicate {
    return this.getWoStatusPredicate("workOrderStatusId");
  }

  public async attached() {
    this.disposables.push(
      this.bindingEngine.propertyObserver(this.parameters, "workOrderId").subscribe(async (newValue, oldValue) => {
        if (!this.isFetchingParams && newValue != null && newValue != oldValue) {
          this.isFetchingParams = true;
          this.parameters.workOrderId = null;
          this.parameters.workOrder = await this.workOrderService.getEntityById(newValue);
          this.parameters.workOrderId = newValue;
          UIInternal.nextTick(() => { this.isFetchingParams = false });
        }
      }),
    );
  }

  private async generate() {
    this.isLoading = true;
    try {
      let response = await this.http.post(this.urlReporting, JSON.stringify(this.parameters, this.replacerOfParameters));
      if (response.ok) {
        this.previewUrl = await response.text();
      } else {
        this.previewUrl = null;
        this.logger.LogError(this.i18n.tr('report.generateFailed'), null, null, true);
        console.warn(response.statusText);
      }
    } catch (e) {
      console.error(e);
      this.logger.LogError(this.i18n.tr('report.generateFailed'), null, null, true);
    }
    this.isLoading = false;
  }

  protected replacerOfParameters(key, val) {
    if (key == 'workOrder') return null;
    return val
  }
}

export abstract class ReportParameters {
  startDate: Date;
  endDate: Date;
  workOrderId: number | null;
  workOrder: Zeus.Web.Model.WorkOrder;
  status: Array<number>;
  onlyShortage: boolean;

  constructor() {
    this.status = [];
  }
}
