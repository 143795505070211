import { Config, EnumerationTypeCategory, ExtendedRouteConfig, PermissionsConfig } from 'digiwall-lib';
import * as breeze from 'breeze-client';
import { AppModuleEnum } from './constants';
import { Container } from 'aurelia-dependency-injection';

export abstract class AppModuleRoot {
  public abstract get name(): string;
  public abstract get translations(): { [key: string]: object };
  public abstract get enumerationTypeCategories(): EnumerationTypeCategory[];
  public abstract get enumerationTypeList(): object;
  public abstract setupModel(metadataStore: breeze.MetadataStore, manager: breeze.EntityManager): void;

  protected abstract get pathName(): string;
  protected abstract get routes(): AppModuleRouteConfig[];
  protected abstract get resourceNames(): {};
  protected abstract get permissionsConfig(): PermissionsConfig;

  public registerServices(instance: Container): void { }

  public setupRoutes(config: Config) {
    let moduleRoutes = this.routes;
    moduleRoutes?.forEach(moduleRoute => {
      this.mapModuleId(moduleRoute);
      let index: number = -1;
      if (moduleRoute.after) {
        index = this.findInsertionIndex(config.routes, moduleRoute.after);
        if (index >= 0) {
          index++; // Insert after
        }
      }
      if (moduleRoute.before && index < 0) {
        index = this.findInsertionIndex(config.routes, moduleRoute.before);
      }
      config.routes.splice(index, 0, moduleRoute);
    });
  }

  public setupResourceNames(config: Config) {
    Object.assign(config.globalConfig.resourceNames, this.resourceNames);
  }

  public setupPermissions(config: PermissionsConfig): void {
    if (config?.perContext == null || config?.permissionNames == null) {
      return;
    }

    config.perContext.push(...this.permissionsConfig.perContext || []);
    if (this.permissionsConfig.permissionNames != null) {
      Object.keys(this.permissionsConfig.permissionNames).forEach(key =>
        config.permissionNames[key] = Object.assign(config.permissionNames[key] ?? {}, this.permissionsConfig.permissionNames[key])
      );
    }
  }

  private findInsertionIndex(routes: ExtendedRouteConfig[], search: string) {
    return routes.findIndex(x => x.route == search || x.moduleId == search || x.name == search || x.href == search);
  }

  private mapModuleId(route: AppModuleRouteConfig) {
    if (route.moduleId?.startsWith('/')) {
      // Absolute route, don't touch (remove '/')
      route.moduleId = route.moduleId.substring(1);
    } else if (route.moduleId != null) {
      route.moduleId = `app-modules/${this.pathName}/${route.moduleId}`;
    }
  }

  protected mergeTranslations(jsons: Array<{ [key: string]: object; }>): { [key: string]: object; } {
    let mergedTranslations = {};
    jsons.forEach(json => {
      Object.keys(json).forEach(lang => {
        mergedTranslations[lang] = this.deepMerge(mergedTranslations[lang] || {}, json[lang] || {});
      });
    })
    return mergedTranslations;
  }

  private deepMerge(objA: {}, objB: {}) {
    const result = { ...objA }; // Copy objA into a new object

    for (const key in objB) {
      if (objB.hasOwnProperty(key)) {
        if (
          typeof objB[key] === 'object' &&
          objB[key] !== null &&
          !Array.isArray(objB[key]) &&
          typeof result[key] === 'object' &&
          result[key] !== null &&
          !Array.isArray(result[key])
        ) {
          // Recursively merge if both values are objects
          result[key] = this.deepMerge(result[key], objB[key]);
        } else {
          // Otherwise, overwrite the value
          result[key] = objB[key];
        }
      }
    }

    return result;
  }
}

export class UnkownAppModuleRoot extends AppModuleRoot {
  constructor(private module: AppModuleEnum) {
    super();
  }
  public get name(): string {
    return AppModuleEnum[this.module];
  }
  public get translations(): { [key: string]: object; } {
    return {};
  }
  public get enumerationTypeCategories(): EnumerationTypeCategory[] {
    return [];
  }
  public get enumerationTypeList(): object {
    return {};
  }
  protected get pathName(): string {
    return 'cart-reception';
  }
  protected get routes(): AppModuleRouteConfig[] {
    return [];
  }
  protected get resourceNames(): {} {
    return {};
  }
  protected get permissionsConfig(): PermissionsConfig {
    return {};
  }
  public setupModel(metadataStore: breeze.MetadataStore, manager: breeze.EntityManager) { }
}

export interface AppModuleRouteConfig extends ExtendedRouteConfig {
  after?: string;
  before?: string;
}
