import { HttpClient } from 'aurelia-fetch-client';
import { BindingEngine, autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { CustomLogger, ServiceBase, UIInternal, } from 'digiwall-lib';
import { Zeus } from 'generated';
import * as Constants from '../../../../constants';
import * as RKConstants from '../../constants';
import { FilterQueryOp, Predicate } from 'breeze-client';
import { BaseReport, ReportParameters } from '../base-report/base-report';

@autoinject
export class InputReport extends BaseReport<InputReportParameters> {
  protected urlReporting = RKConstants.Application.InputReport;

  private workOrderPOService: ServiceBase<Zeus.Web.Model.WorkOrder>;
  private workOrderASNService: ServiceBase<Zeus.Web.Model.WorkOrder>;

  constructor(logger: CustomLogger, http: HttpClient, i18n: I18N, bindingEngine: BindingEngine) {
    super(logger, http, i18n, bindingEngine);
    this.workOrderPOService = new ServiceBase<Zeus.Web.Model.WorkOrder>(Constants.EntityTypeNames.WorkOrder);
    this.workOrderASNService = new ServiceBase<Zeus.Web.Model.WorkOrder>(Constants.EntityTypeNames.WorkOrder);
    this.parameters = new InputReportParameters();
  }

  public async activate(params) {
    await super.activate(params);
    this.workOrderPOService.gridDataSource.customSelect2Predicates = () => Predicate.and(this.getWoPredicate(), new Predicate("inputPO", FilterQueryOp.NotEquals, null));
    this.workOrderASNService.gridDataSource.customSelect2Predicates = () => Predicate.and(this.getWoPredicate(), new Predicate("inputASN", FilterQueryOp.NotEquals, null));
  }

  protected getWoPredicate(): Predicate {
    return Predicate.and(...[
      new Predicate("workOrderTypeId", FilterQueryOp.Equals, Constants.WorkOrderType.Input),
      this.getWoStatusPredicate("workOrderStatusId")
    ]);
  }

  public async attached() {
    this.disposables.push(
      this.bindingEngine.propertyObserver(this.parameters, "workOrderPoId").subscribe(async (newValue, oldValue) => {
        if (!this.isFetchingParams && newValue != null && newValue != oldValue) {
          this.isFetchingParams = true;
          this.parameters.workOrderPoId = null;
          this.parameters.workOrderPo = await this.workOrderService.getEntityById(newValue);
          this.parameters.workOrderPoId = newValue;
          UIInternal.nextTick(() => { this.isFetchingParams = false });
        }
      }),
      this.bindingEngine.propertyObserver(this.parameters, "workOrderAsnId").subscribe(async (newValue, oldValue) => {
        if (!this.isFetchingParams && newValue != null && newValue != oldValue) {
          this.isFetchingParams = true;
          this.parameters.workOrderAsnId = null;
          this.parameters.workOrderAsn = await this.workOrderService.getEntityById(newValue);
          this.parameters.workOrderAsnId = newValue;
          UIInternal.nextTick(() => { this.isFetchingParams = false });
        }
      }),
    );
    super.attached();
  }

  protected override replacerOfParameters(key, val) {
    if (key == 'workOrderPo' || key == 'workOrderAsn') return null;
    return super.replacerOfParameters(key, val);
  }
}

class InputReportParameters extends ReportParameters {
  workOrderPoId: number | null;
  workOrderPo: Zeus.Web.Model.WorkOrder;
  workOrderAsnId: number | null;
  workOrderAsn: Zeus.Web.Model.WorkOrder;
}
