export class Mutex {
  private mutex = Promise.resolve();
  private _isLocked = false;

  public get isLocked() {
    return this._isLocked;
  }

  public async lock<T>(fn: () => Promise<T>): Promise<T> {
    const oldMutex = this.mutex;
    let release: () => void;
    this.mutex = new Promise<void>(resolve => (release = resolve));
    await oldMutex;
    this._isLocked = true;
    try {
      const result = await fn();
      return result;
    } finally {
      this._isLocked = false;
      release!();
    }
  }
}
