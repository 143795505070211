import { AppModuleProvider } from './app-modules/app-module-provider';
import { autoinject, PLATFORM, singleton } from 'aurelia-framework';
import { Actions, addRolesToRoutes, addUsersToRoutes, Config, ExtendedRouteConfig, applyDefaultPermissionsConfig } from 'digiwall-lib';
import * as Constants from './constants';
import * as toastr from 'toastr';
import environment from './environment';
import { Endpoints } from 'endpoints';
import { WorkOrderUtils } from 'utils/work-order-utils';

@autoinject
@singleton(Config)
export class ZeusConfig extends Config {

  constructor(private appModuleProvider: AppModuleProvider) {
    super(environment);
    this.globalConfig.applicationTitle = "Vanasoft WMS";
    this.globalConfig.configureMetadataStore = null;
    this.globalConfig.breezeControllerUri = this.globalConfig.remoteServiceName + '/breeze/Zeus';
    this.globalConfig.remoteFileStorageServiceConfig = {
      secure: false,
      controller: "/api/file/",
      canDownloadFiles: true,
      ignoreUris: [
        "https://ui-avatars.com"
      ]
    };
    this.globalConfig.resourceNames = Constants.EntityTypeNames.ResourceNames;
    this.appModuleProvider.all(x => x.setupResourceNames(this));

    this.authConfig.anonymousUrls = [
      Endpoints.AppModule.AppModulesActiveModules,
      Endpoints.AppModule.AppModulesIsDevelopment,
      Endpoints.Log.LogFrontendError,
    ];

    this.agGridConfig.showInFloatingBox = true;
    this.agGridConfig.hasHyperlinkColumn = false;

    this.communicationConfig.showHistoryChatInButton = true;

    this.enumerationTypeConfig.listByCategory = false;
    this.enumerationTypeConfig.isUniqueCodeRequired = true;
    this.enumerationTypeConfig.completeTranslatedFields = true;
    this.enumerationTypeConfig.enumerationTypes = Constants.EnumerationTypes;
    this.enumerationTypeConfig.enumerationTypeCategories = [
      { category: Constants.EnumerationTypes.UnitOfMeasure, isClosed: false, name: "enumerationtype.unitOfMeasure" },
      { category: Constants.EnumerationTypes.StorageLocationType, isClosed: false, name: "enumerationtype.storageLocationType" },
      { category: Constants.EnumerationTypes.BatchProcessExecutionStatus, isClosed: true, name: "enumerationtype.batchProcessExecutionStatus" },
      { category: Constants.EnumerationTypes.BatchProcessResultStatus, isClosed: true, name: "enumerationtype.batchProcessResultStatus" },
      { category: Constants.EnumerationTypes.WorkOrderType, isClosed: true, name: "enumerationtype.workOrderType" },
      { category: Constants.EnumerationTypes.DataFileImportType, isClosed: true, name: "enumerationtype.dataFileImportType" },
      { category: Constants.EnumerationTypes.InventoryTarget, isClosed: true, name: "enumerationtype.inventoryTarget" },
      { category: Constants.EnumerationTypes.ArticleRotationLevel, isClosed: false, name: "enumerationtype.articleRotationLevel" },
      { category: Constants.EnumerationTypes.WorkOrderStatus, isClosed: true, name: "enumerationtype.workOrderStatus" },
      { category: Constants.EnumerationTypes.InventoryBlock, isClosed: false, name: "enumerationtype.inventoryBlock" },
      { category: Constants.EnumerationTypes.OrderSubtype, isClosed: true, name: "enumerationtype.orderSubtype" },
      { category: Constants.EnumerationTypes.StorageStrategy, isClosed: true, name: "enumerationtype.storageStrategy" },
      { category: Constants.EnumerationTypes.DocumentPrinter, isClosed: false, name: "enumerationtype.documentPrinter" },
    ];

    toastr.options.timeOut = 4000;
    toastr.options.positionClass = "toast-bottom-right";
    toastr.options.progressBar = false;
    toastr.options.extendedTimeOut = 60 * 1000;
    toastr.options.preventDuplicates = true;
    toastr.options.closeButton = true;
    toastr.options.closeHtml = '<button><i class="digi-cross"></i></button>';

    addUsersToRoutes(this.routes, { after: Constants.RouteName.Locations, resource: Constants.EntityTypeNames.ZeusUser, list: { moduleId: 'users/user-list' }, detail: { moduleId: 'users/user-detail' } });
    addRolesToRoutes(this.routes);

    this.permissionsConfig = {
      perContext: [
        { context: Constants.Permissions.Helpdesk.split(':')[1], qualifiedName: [] },
        { context: Constants.EntityTypeNames.StorageGroup, qualifiedName: [Constants.MenuType.Storages, 'storagegroup.storagegroups'] },
        { context: Constants.EntityTypeNames.Storage, qualifiedName: [Constants.MenuType.Storages, 'menu.storages'] },
        { context: Constants.EntityTypeNames.ArticleLocation, qualifiedName: [Constants.MenuType.Articles], isCrud: false },
        { context: Constants.EntityTypeNames.BatchProcess, qualifiedName: [Constants.MenuType.WorkOrders] },
        { context: 'storage-group-parameter', qualifiedName: [Constants.MenuType.WorkOrders, 'workorderlinetoprocess.storageGroupParameters'] },
        { context: Constants.EntityTypeNames.Article, qualifiedName: [Constants.MenuType.Articles, Constants.MenuType.Articles] },
        { context: 'tray', qualifiedName: [Constants.MenuType.Storages] },
        { context: 'work-order', qualifiedName: [Constants.MenuType.WorkOrders, 'workorder.orders'] },
        { context: 'work-order-type-input', qualifiedName: [Constants.MenuType.WorkOrders, 'workorder.input'] },
        { context: 'work-order-type-picking', qualifiedName: [Constants.MenuType.WorkOrders, 'workorder.picking'] },
        { context: 'work-order-type-inventory', qualifiedName: [Constants.MenuType.WorkOrders, 'workorder.inventory'] },
        { context: 'input-order-missing-data', qualifiedName: [Constants.MenuType.WorkOrders, 'workorder.inputOrdersMissingData'] },
        { context: 'document-print-configuration', qualifiedName: [Constants.MenuType.ApplicationParameters, 'documentprintconfiguration.documentprintconfiguration'] }
      ],
      permissionNames: {
        'helpdesk': {
          [Actions.All]: 'user.helpdesk'
        },
        'batch-process': {
          [Constants.Permissions.CancelBatchProcess]: 'menu.quit',
        },
        'storage-group': {
          [Constants.Permissions.FreeSiteSelection]: 'storagegroup.freeSiteSelection'
        },
        'article-location': {
          [Actions.Delete]: 'location.canDeleteArticleLocation'
        },
        'article': {
          ["add-inventory"]: 'article.addInventoryPermission'
        },
        'tray': {
          [Constants.Permissions.Consult]: 'traycontainer.consult'
        },
        'work-order-type-input': {
          [Constants.Permissions.LaunchBatchProcess]: 'workorder.launchPerType.input',
        },
        'work-order-type-picking': {
          [Constants.Permissions.LaunchBatchProcess]: 'workorder.launchPerType.picking',
        },
        'work-order-type-inventory': {
          [Constants.Permissions.LaunchBatchProcess]: 'workorder.launchPerType.inventory',
        }
      }
    }

    applyDefaultPermissionsConfig(this.permissionsConfig);
  }

  private makePermissionSettingsPerWoType(action: string | string[]) {
    return WorkOrderUtils.resourcesPerType.map(resource => {
      return { resource, action };
    })
  }

  public routes: ExtendedRouteConfig[] = [
    // Custom
    { route: ['login/:fragment?'], name: 'login', moduleId: 'auth/login', title: "Login", nav: false },
    { route: 'callback', name: 'callback', moduleId: 'auth/callback' },

    //#region Hidden Routes
    {
      route: 'work-orders/:param1', moduleId: 'work-orders/work-order-detail', title: "workorder.workorders", nav: false, href: "/work-orders/-100", settings: {
        permissionSettings: [{ resource: Constants.EntityTypeNames.WorkOrder, action: Actions.Read }, ...this.makePermissionSettingsPerWoType([Actions.Create, Actions.Update])]
      }
    }, // Note: Create/Update delete is dependant per type, so not here in the settings
    { route: 'order-tracking/:param1', moduleId: 'order-tracking/order-tracking-detail', title: "", nav: false, settings: { resource: Constants.EntityTypeNames.DynamicStorageSentMessage }, href: "order-tracking/-100" },
    { route: 'action-history/:param1', moduleId: 'action-histories/action-history-detail', title: "actionhistory.actionHistory", nav: false, settings: { resource: Constants.EntityTypeNames.ActionHistory }, href: "/action-history/-100" },
    { route: 'work-order-priorities/:param1', moduleId: 'work-order-priorities/work-order-priority-detail', title: "workorderpriority.workorderpriority", nav: false, settings: { resource: Constants.EntityTypeNames.WorkOrderPriority, action: [Actions.Read] }, href: "/work-order-priorities/-100" },
    { route: 'projects/:param1', moduleId: 'projects/project-detail', title: "project.project", nav: false, settings: { resource: Constants.EntityTypeNames.Project, action: [Actions.Read] }, href: "/projects/-100" },
    { route: 'cost-centers/:param1', moduleId: 'cost-centers/cost-center-detail', title: "costcenter.costcenter", nav: false, settings: { resource: Constants.EntityTypeNames.CostCenter, action: [Actions.Read] }, href: "/cost-centers/-100" },

    { route: 'articles/:param1', moduleId: 'articles/article-detail', title: "article.article", nav: false, settings: { resource: Constants.EntityTypeNames.Article, action: [Actions.Read] }, href: "/articles/-100" },
    { route: 'suppliers/:param1', moduleId: 'suppliers/supplier-detail', title: "supplier.supplier", nav: false, settings: { resource: Constants.EntityTypeNames.Supplier, action: [Actions.Read] }, href: "/suppliers/-100" },

    { route: 'location-formats/:param1/', moduleId: 'location-formats/location-format-detail', title: "locationformat.locationformat", nav: false, href: "/location-format/-100", settings: { resource: Constants.EntityTypeNames.LocationFormat, action: [Actions.Read] } },
    { route: 'tray-templates/:param1/', moduleId: 'tray-templates/tray-template-detail', title: "traytemplate.traytemplate", nav: false, href: "/tray-templates/-100", settings: { resource: Constants.EntityTypeNames.TrayTemplate, action: [Actions.Read] } },
    { route: 'locations/:param1', moduleId: 'locations/location-list', title: "", nav: false, settings: { resource: Constants.EntityTypeNames.Location } },
    { route: 'locations-detail/:storage/:tray/:param1', moduleId: 'locations/location-detail', title: "", nav: false, settings: { resource: Constants.EntityTypeNames.Location, action: [Actions.Read] }, href: "/locations-detail/-100" },
    { route: 'locations-detail/:storage/:param1', moduleId: 'locations/location-detail', title: "", nav: false, settings: { resource: Constants.EntityTypeNames.Location, action: [Actions.Read] }, href: "/locations-detail/-100" },
    { route: 'locations-detail/:param1', moduleId: 'locations/location-detail', title: "", nav: false, settings: { resource: Constants.EntityTypeNames.Location, action: [Actions.Read] }, href: "/locations-detail/-100" },

    { route: 'sent-messages/:param1', moduleId: 'sent-messages/sent-message-detail', title: "menu.sentMessage", nav: false, settings: { resource: Constants.EntityTypeNames.StorageGroup }, href: "/sent-messages/-100" },
    { route: 'receive-messages/:param1', moduleId: 'receive-messages/receive-message-detail', title: "menu.receivedMessage", nav: false, settings: { resource: Constants.EntityTypeNames.StorageGroup }, href: "/receive-messages/-100" },

    { route: 'enumeration-types/:param2/:param1', moduleId: PLATFORM.moduleName('digiwall-lib/vm/enumeration-types/enumeration-type-detail'), nav: false, settings: { resource: Constants.EntityTypeNames.EnumerationType, action: [Actions.Read] }, href: `/enumeration-types/work-order-type/0/-100` },
    { route: 'storage-volumes/:param1', moduleId: 'storage-volumes/storage-volume-detail', nav: false, settings: { resource: Constants.EntityTypeNames.StorageVolume }, href: `/storage-volumes/-100` },
    { route: 'algorithms/picking/:param1', moduleId: 'algorithms/algorithm-detail-picking', title: "algorithm.pickingAlgorithm", nav: false, settings: { resource: Constants.EntityTypeNames.InputPickingAlgorithm, action: [Actions.Read] }, href: "/algorithms/picking/-100" },
    { route: 'algorithms/input/:param1', moduleId: 'algorithms/algorithm-detail-input', title: "algorithm.inputAlgorithm", nav: false, settings: { resource: Constants.EntityTypeNames.InputPickingAlgorithm, action: [Actions.Read] }, href: "/algorithms/input/-100" },


    { route: 'positions/:param1', moduleId: 'tray-templates/position-detail', title: "", nav: false, settings: { resource: Constants.EntityTypeNames.Location, action: [Actions.Read] }, href: "/positions/-100" },
    { route: 'bay-devices/:param2/:param1', moduleId: 'bay-devices/bay-device-detail', title: "", nav: false, settings: { resource: Constants.EntityTypeNames.BayDevice }, href: "/bay-devices/:param2/-100" },

    { route: 'input-order-missing-data/:param1', moduleId: 'input-orders-missing-data/input-order-missing-data-detail', title: "workorder.inputOrderMissingData", nav: false, settings: { resource: Constants.Permissions.InputOrdersMissingData, action: [Actions.Read] }, href: "/input-order-missing-data/-100" },
    //#endregion

    //#region MenuType Routes
    { route: ['', 'home'], moduleId: 'home-page/home-page', title: "menu.homePage", nav: true, settings: { group: Constants.MenuType.HomePage, action: Actions.Read, icon: "digi-home" } },
    { route: 'work-orders/all', moduleId: 'work-orders/work-order-list', title: "menu.orders", nav: true, settings: { group: Constants.MenuType.WorkOrders, resource: Constants.EntityTypeNames.WorkOrder, action: Actions.Read } },
    { route: 'work-orders-to-process/all', moduleId: 'work-orders-to-process/work-order-to-process', title: "menu.orderInProcess", nav: true, settings: { group: Constants.MenuType.WorkOrders, permissionSettings: this.makePermissionSettingsPerWoType(Constants.Permissions.LaunchBatchProcess) } },
    { route: 'work-orders-to-process/all/:param1', moduleId: 'work-orders-to-process/work-order-to-process', title: "menu.orderInProcess", nav: false, settings: { group: Constants.MenuType.WorkOrders, permissionSettings: this.makePermissionSettingsPerWoType(Constants.Permissions.LaunchBatchProcess) }, href: "work-orders-to-process/all" },
    { route: 'process-monitoring/all', name: Constants.RouteName.ProcessMonitoring, moduleId: 'process-monitoring/process-monitoring', title: "menu.processMonitoring", nav: true, settings: { group: Constants.MenuType.WorkOrders, resource: Constants.EntityTypeNames.BatchProcess, action: Actions.Read } },
    { route: 'order-tracking/all', moduleId: 'order-tracking/order-tracking-list', title: "menu.orderTracking", nav: true, settings: { group: Constants.MenuType.WorkOrders, resource: Constants.EntityTypeNames.DynamicStorageSentMessage, action: Actions.Read } },
    { route: 'order-tracking/all/:param1', moduleId: 'order-tracking/order-tracking-list', title: "menu.orderTracking", nav: false, settings: { group: Constants.MenuType.WorkOrders, resource: Constants.EntityTypeNames.DynamicStorageSentMessage, action: Actions.Read }, href: "order-tracking/all" },
    { route: 'action-history/all', moduleId: 'action-histories/action-history-list', title: "actionhistory.actionHistory", nav: true, settings: { group: Constants.MenuType.WorkOrders, resource: Constants.EntityTypeNames.ActionHistory, action: Actions.Read } },
    { route: 'action-history/all/:filter', moduleId: 'action-histories/action-history-list', title: "actionhistory.actionHistory", nav: false, settings: { group: Constants.MenuType.WorkOrders, resource: Constants.EntityTypeNames.ActionHistory, action: Actions.Read }, href: "action-history/all" },
    { route: 'work-order-priorities/all', name: 'work-order-priorities', moduleId: 'work-order-priorities/work-order-priority-list', title: "menu.work-order-priorities", nav: true, settings: { group: Constants.MenuType.WorkOrders, resource: Constants.EntityTypeNames.WorkOrderPriority, action: Actions.Read } },
    { route: 'input-orders-missing-data/all', name: 'input-orders-missing-data', moduleId: 'input-orders-missing-data/input-order-missing-data-list', title: "menu.input-orders-missing-data", nav: true, settings: { group: Constants.MenuType.WorkOrders, resource: Constants.Permissions.InputOrdersMissingData, action: Actions.Read } },
    { route: 'projects/all', name: 'projects', moduleId: 'projects/project-list', title: "project.projects", nav: true, settings: { group: Constants.MenuType.WorkOrders, resource: Constants.EntityTypeNames.Project, action: Actions.Read } },
    { route: 'cost-centers/all', name: 'cost-centers', moduleId: 'cost-centers/cost-center-list', title: "costcenter.costcenters", nav: true, settings: { group: Constants.MenuType.WorkOrders, resource: Constants.EntityTypeNames.CostCenter, action: Actions.Read } },

    { route: 'articles/all', name: 'articles', moduleId: 'articles/article-list', title: "menu.articles", nav: true, settings: { group: Constants.MenuType.Articles, resource: Constants.EntityTypeNames.Article, action: Actions.Read } },
    { route: 'article-families/:param1', name: 'article-families', moduleId: 'article-families/article-family-detail', title: "menu.articlefamilies", nav: true, settings: { group: Constants.MenuType.Articles, resource: Constants.EntityTypeNames.ArticleFamily, action: Actions.Read }, href: "/article-families/all" },
    { route: 'suppliers/all', name: 'suppliers', moduleId: 'suppliers/supplier-list', title: "menu.suppliers", nav: true, settings: { group: Constants.MenuType.Articles, resource: Constants.EntityTypeNames.Supplier, action: Actions.Read } },

    { route: 'storages/:param1', name: Constants.RouteName.Storages, moduleId: 'storages/storage-list', title: "menu.storagePlaces", nav: true, settings: { group: Constants.MenuType.Storages, resource: Constants.EntityTypeNames.StorageGroup, action: Actions.Read, icon: "ze-article-group-storage" }, href: '/storages/all' },
    { route: 'location-formats/all', name: 'location-formats', moduleId: 'location-formats/location-format-list', title: "menu.locationFormats", nav: true, settings: { group: Constants.MenuType.Storages, resource: Constants.EntityTypeNames.LocationFormat, action: Actions.Read } },
    { route: 'tray-templates/all', name: 'traytemplate', moduleId: 'tray-templates/tray-template-list', title: "traytemplate.traytemplates", nav: true, settings: { group: Constants.MenuType.Storages, resource: Constants.EntityTypeNames.TrayTemplate, action: Actions.Read } },
    { route: 'locations/all', name: Constants.RouteName.Locations, moduleId: 'locations/location-list', title: "location.locations", nav: true, settings: { group: Constants.MenuType.Storages, resource: Constants.EntityTypeNames.Location, action: Actions.Read, icon: "ze-article-location" } },

    { route: 'send-message', name: 'send-messages', moduleId: 'send-messages/send-message', title: "menu.sendMessage", nav: true, settings: { group: Constants.MenuType.Tests, resource: Constants.Roles.Helpdesk, action: Actions.All } },
    { route: 'sent-messages/all', name: Constants.RouteName.SentMessage, moduleId: 'sent-messages/sent-message-list', title: "menu.sentMessages", nav: true, settings: { group: Constants.MenuType.Tests, resource: Constants.Roles.Helpdesk, action: Actions.All } },
    { route: 'receive-messages/all', name: 'receive-messages', moduleId: 'receive-messages/receive-message-list', title: "menu.receivedMessages", nav: true, settings: { group: Constants.MenuType.Tests, resource: Constants.Roles.Helpdesk, action: Actions.All } },
    { route: 'parc-generator/generate', name: 'parc-generator', moduleId: 'parc-locations-generator/parc-locations-generator', title: "sitegenerator.parcLocationsGenerator", nav: true, settings: { group: Constants.MenuType.Tests, resource: Constants.Roles.Helpdesk, action: Actions.All } },
    { route: 'reset-data/reset-data', name: 'reset-data', moduleId: 'reset-data/reset-data', title: "resetdata.resetData", nav: true, settings: { group: Constants.MenuType.Tests, resource: Constants.Roles.Helpdesk, action: Actions.All } },

    { route: 'application-parameters/all', name: Constants.RouteName.AppParam, moduleId: 'application-parameters/application-parameters', title: "applicationparameters.applicationparameters", nav: true, settings: { group: Constants.MenuType.ApplicationParameters, resource: Constants.EntityTypeNames.ApplicationParameters, action: [Actions.Read, Actions.Update], icon: 'digi-options' } },
    { route: 'hardware-parameters/all', moduleId: 'hardware-parameters/hardware-parameters', title: "hardwareparameters.hardwareparameters", nav: true, settings: { group: Constants.MenuType.ApplicationParameters, resource: Constants.EntityTypeNames.HardwareParameters, action: [Actions.Read, Actions.Update] } },
    { route: 'enumeration-types/all', moduleId: PLATFORM.moduleName('digiwall-lib/vm/enumeration-types/enumeration-type-list-view-model-base'), title: "enumerationtype.enumerationtypes", nav: true, settings: { group: Constants.MenuType.ApplicationParameters, resource: Constants.EntityTypeNames.EnumerationType, action: Actions.Read }, href: `/enumeration-types/all` },
    { route: 'storage-volumes/all', moduleId: 'storage-volumes/storage-volume-list', title: "storagevolume.storagevolumes", nav: true, settings: { group: Constants.MenuType.ApplicationParameters, resource: Constants.EntityTypeNames.EnumerationType, action: Actions.Read }, href: `/storage-volumes/all` },
    { route: 'data-file-import-log/all', name: Constants.RouteName.ImportLog, moduleId: 'data-file-import-log/data-file-import-log-list', title: "importlog.importLog", nav: true, settings: { group: Constants.MenuType.ApplicationParameters, resource: Constants.EntityTypeNames.DataFileImportLog, action: Actions.Read } },
    { route: 'document-print-configurations/all', moduleId: 'document-print-configurations/document-print-configurations', title: "documentprintconfiguration.documentprintconfiguration", nav: true, settings: { group: Constants.MenuType.ApplicationParameters, resource: Constants.EntityTypeNames.DocumentPrintConfiguration, action: Actions.Read } },
    { route: 'algorithms/all', moduleId: 'algorithms/algorithm-list', title: "algorithm.algorithms", nav: true, settings: { group: Constants.MenuType.ApplicationParameters, resource: Constants.EntityTypeNames.InputPickingAlgorithm, action: Actions.Read } },
    { route: 'gigasoft-migration', moduleId: 'gigasoft-migration/gigasoft-migration', title: "Gigasoft Migration", nav: true, settings: { group: Constants.MenuType.ApplicationParameters, resource: Constants.Roles.Helpdesk, action: Actions.All } }
    //#endregion
  ];


  public init(): void {
    //empty
  }
}
