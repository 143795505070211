import { HttpClient, json } from 'aurelia-fetch-client';
import { Router } from 'aurelia-router';
import { CamelCase, CustomLogger, EnumerationTypeService, ServiceBase, ViewModelBase } from 'digiwall-lib';
import { autoinject, BindingEngine, computedFrom, viewResources } from 'aurelia-framework';
import * as toastr from 'toastr';
import { DialogController } from 'aurelia-dialog';
import { DataFormat } from 'select2';
import { FilterQueryOp, Predicate } from 'breeze-client';
import * as LPConstants from '../constants';
import { LabelPrinting } from '../generated';
import * as Constants from '../../../constants';
import { Zeus } from 'generated';
import { StorageSelection } from 'locations/storage-selection/storage-selection';
import { LocationSelectedList } from './location-selected-list/location-selected-list';
import { ArticleSelectedList } from './article-selected-list/article-selected-list';
import { Utils } from 'utils/utils';
import { ApiService } from '../api-service/api.service';

@autoinject
@viewResources(LocationSelectedList, StorageSelection, ArticleSelectedList)
export class PrintLabel extends ViewModelBase {
  public ribbonHeaderText: string = this.i18n.tr("printlabel.printlabel");
  public ressourceName: string = LPConstants.EntityTypeNames.LabelTemplate;

  private labelTypeService: EnumerationTypeService;
  private labelPrinterService: EnumerationTypeService;

  private labelTemplateService: ServiceBase<LabelPrinting.Model.LabelTemplate>;
  private labelTemplateDefaultSGService: ServiceBase<LabelPrinting.Model.LabelTemplateDefaultStorageGroupPrinter>;
  private locationService: ServiceBase<Zeus.Web.Model.Location>;

  private labelType: DataFormat;
  private labelTemplate: DataFormat;
  private labelPrinter: DataFormat;
  private qtyLabelToPrint: number = 1;
  private isDefaultTemplate: Predicate;

  private locations: Array<Zeus.Web.Model.Location> = [];
  private locationSelectedList: LocationSelectedList;

  private articles: Array<Zeus.Web.Model.Article> = [];
  private articleSelectedList: ArticleSelectedList;

  private isRangeLocationMode: boolean = false;
  private printLabelDTO: LabelPrinting.Model.PrintLabelDTO;
  private isValidDTO: boolean = false;

  constructor(router: Router, logger: CustomLogger, private dialogController: DialogController, private bindingEngine: BindingEngine, private httpClient: HttpClient, private apiService: ApiService) {
    super(router, logger);

    this.labelTypeService = new EnumerationTypeService(LPConstants.EnumerationTypes.LabelType);
    this.labelPrinterService = new EnumerationTypeService(LPConstants.EnumerationTypes.LabelPrinter);

    this.labelTemplateService = new ServiceBase<LabelPrinting.Model.LabelTemplate>(LPConstants.EntityTypeNames.LabelTemplate);
    this.labelTemplateDefaultSGService = new ServiceBase<LabelPrinting.Model.LabelTemplateDefaultStorageGroupPrinter>(LPConstants.EntityTypeNames.LabelTemplateDefaultStorageGroupPrinter);
    this.locationService = new ServiceBase<Zeus.Web.Model.Location>(Constants.EntityTypeNames.Location);
    this.printLabelDTO = {} as LabelPrinting.Model.PrintLabelDTO;
  }

  @computedFrom("labelType.id")
  public get isLocationType() {
    return this.labelType.id == LPConstants.LabelTypeId.Location;
  }

  @computedFrom("labelType.id")
  public get isArticleType() {
    return this.labelType.id == LPConstants.LabelTypeId.Article;
  }

  @computedFrom("labelTemplate", "labelPrinter", "qtyLabelToPrint", "isArticleType", "isLocationType", "locations.length", "isValidDTO")
  public get canPrint() {
    return this.labelTemplate != null
      && this.labelPrinter != null
      && this.qtyLabelToPrint > 0
      && (this.isArticleType ||
        (this.isLocationType &&
          this.locations.length > 0 && (!this.isRangeLocationMode || this.isValidDTO)
        ));
  }

  public async activate(params: any & { labelTypeId: number, isLocationRangeMode: boolean, locations: Array<Zeus.Web.Model.Location>, articles: Array<Zeus.Web.Model.Article> }) {
    await super.activate(params);
    if (params.labelTypeId == null) {
      this.logError(this.i18n.tr("printlabel.errorLabelType"), null, true);
      this.close();
    }
    let defaultTemplate = await this.setDataFormats(params.labelTypeId);
    await this.setLabelPrinter(defaultTemplate, true);
    if (this.isLocationType) {
      this.isRangeLocationMode = params.isLocationRangeMode;
      if (params.locations?.length > 0) {
        let result = await this.httpClient.post(LPConstants.Application.FilterLocationByUserSG, JSON.stringify((params.locations as Array<Zeus.Web.Model.Location>).map(x => x.id)))
        if (result.ok) {
          let ids: Array<number> = await result.json();
          this.locations.push(...params.locations.filter(x => ids.includes(x.id)));
        }
      }
    } else if (this.isArticleType) {
      if (params.articles?.length > 0) {
        this.articles.push(...params.articles);
      }
    }

  }

  public async attached() {
    this.disposables.push(
      this.bindingEngine.propertyObserver(this, "labelTemplate").subscribe(async (newValue, oldValue) => {
        if (newValue != null && newValue != oldValue) {
          await this.setLabelPrinter();
        }
      })
    );
  }

  private async setDataFormats(labelTypeId: number): Promise<LabelPrinting.Model.LabelTemplate> {
    // --- Set LabelType ---
    this.labelType = {
      id: labelTypeId,
      text: (await this.labelTypeService.getEntityById(labelTypeId)).denomination._translation
    } as DataFormat;

    // --- Set LabelTemplate ---
    let sameLabelTypeId = new Predicate('labelTypeId', FilterQueryOp.Equals, this.labelType.id);
    let activeTemplate = new Predicate('activeTemplate', FilterQueryOp.Equals, true);
    this.labelTemplateService.gridDataSource.customSelect2Predicates = () => sameLabelTypeId.and(activeTemplate);

    // -- Default LabelTemplate for LabelType --
    this.isDefaultTemplate = new Predicate('defaultTemplate', FilterQueryOp.Equals, true);
    let defaultTemplate = (await this.labelTemplateService.firstEntity(sameLabelTypeId.and(this.isDefaultTemplate).and(activeTemplate), ['labelPrinter', 'storageGroups.labelPrinter']));
    if (defaultTemplate != null) {
      this.labelTemplate = {
        id: defaultTemplate.id,
        text: defaultTemplate.name._translation
      } as DataFormat;
    }
    else {
      // -- First LabelTemplate link to current user StorageGroup --
      let sgPredicate = new Predicate('storageGroupId', FilterQueryOp.Equals, this.authService.currentUser.userData.defaultSessionSiteManagementId);
      let activePredicate = new Predicate('labelTemplate.activeTemplate', FilterQueryOp.Equals, true);
      defaultTemplate = (await this.labelTemplateDefaultSGService.firstEntity(Predicate.and(activePredicate, sgPredicate), ['labelTemplate.labelPrinter']))?.labelTemplate; // , 'labelTemplate.storageGroups.labelPrinter'
      if (defaultTemplate != null && defaultTemplate.labelTypeId == this.labelType.id) {
        this.labelTemplate = {
          id: defaultTemplate.id,
          text: defaultTemplate.name._translation
        } as DataFormat;
      }
    }
    return defaultTemplate;
  }

  private async setLabelPrinter(defaultTemplate?: LabelPrinting.Model.LabelTemplate, isActivate?: boolean) {
    let labelTemplate;
    if (defaultTemplate != null || (isActivate != null && isActivate)) {
      labelTemplate = defaultTemplate;
    }
    else {
      labelTemplate = (await this.labelTemplateService.getEntityById(this.labelTemplate.id as number, 'labelPrinter', 'storageGroups.labelPrinter'));
    }

    if (labelTemplate != null) {
      // --- If current StorageGroup is the same than one in LabelTemplate ---
      // -- Current StorageGroup printer for LabelType --
      let currentSGPrinter = this.getCurrentStorageGroupPrinter(labelTemplate.storageGroups);
      if (currentSGPrinter != null) {
        this.labelPrinter = {
          id: currentSGPrinter.id,
          text: currentSGPrinter.denomination._translation
        } as DataFormat;
      }
      else {
        // --- If no StorageGroup default printers in LabelTemplate or not the same SG than the current one ---
        // -- LabelTemplate default printer --
        if (labelTemplate.labelPrinter != null) {
          this.labelPrinter = {
            id: labelTemplate.labelPrinterId,
            text: labelTemplate.labelPrinter.denomination._translation
          } as DataFormat;
        }
      }
    }

    if (this.labelPrinter == null) {
      // --- If no default printer for LabelTemplate ---
      // -- EnumType default printer --
      let defaultPrinter = await this.labelPrinterService.getDefault();
      if (defaultPrinter != null) {
        this.labelPrinter = {
          id: defaultPrinter.id,
          text: defaultPrinter.denomination._translation
        } as DataFormat;
      }
    }
  }

  private getCurrentStorageGroupPrinter(labelTemplateStorageGroups: Array<LabelPrinting.Model.LabelTemplateDefaultStorageGroupPrinter>) {
    for (let labelTempSG of labelTemplateStorageGroups) {
      if (labelTempSG.storageGroupId == this.authService.currentUser.userData.defaultSessionSiteManagementId) {
        return labelTempSG.labelPrinter;
      }
    }
    return null;
  }

  private async generatePreview() {
    if (true == this.isValidDTO) {
      let response: Response = await this.httpClient.post(Constants.Application.RemoteServiceName + LPConstants.Application.GetLocationsToPrint, json(this.printLabelDTO));
      if (response.ok) {
        this.locations.splice(0);
        this.locations.push(...JSON.parse(await response.text(), (key: string, value: any) => { return CamelCase.convertKeysToCamelCase(key, value) }));
      } else {
        toastr.error(this.i18n.tr("location.cantGeneratePreview"));
      }
    }
  }

  private async print() {
    if (this.labelTemplate == null) {
      return toastr.error(this.i18n.tr("printlabel.labelTemplateMandatory"));
    }
    if (this.labelPrinter == null) {
      return toastr.error(this.i18n.tr("printlabel.labelPrinterMandatory"));
    }
    if (this.qtyLabelToPrint == null || this.qtyLabelToPrint < 1) {
      return toastr.error(this.i18n.tr("printlabel.qtyLabelToPrintMandatory"));
    }

    this.printLabelDTO.labelTypeId = Utils.extractIdFromDataFormat(this.labelType);
    this.printLabelDTO.labelTemplateId = Utils.extractIdFromDataFormat(this.labelTemplate);
    this.printLabelDTO.labelPrinterId = Utils.extractIdFromDataFormat(this.labelPrinter);
    this.printLabelDTO.quantity = this.qtyLabelToPrint;

    if (this.isLocationType) {
      this.printLabelDTO.isRangeLocationMode = this.isRangeLocationMode;
      if (this.isRangeLocationMode == false) {
        this.printLabelDTO.locationIds = this.locationSelectedList.selectedEntities.map(x => x.id);
      }
    } else if (this.isArticleType) {
      this.printLabelDTO.articleIds = this.articleSelectedList.selectedEntities.map(x => x.id);
    }

    let response = await this.apiService.printLabel(this.printLabelDTO);
    if (response == null) {
      this.logger.LogError(this.i18n.tr("printlabel.printError") + " : " + 'Print label DTO is null !', null, null, true);
      return;
    }
    let responseText = await response.text();
    if (response.ok) {
      let endMessage = "";
      if (this.isLocationType) {
        endMessage = this.i18n.tr("location.locations");
      } else if (this.isArticleType) {
        endMessage = this.i18n.tr("article.articles");
      }
      this.logger.Log(this.i18n.tr("printlabel.printSuccess") + " : " + responseText + " " + endMessage, null, null, true);
      this.dialogController.ok();
    } else {
      this.logger.LogError(this.i18n.tr("printlabel.printError") + " : " + responseText, null, null, true);
    }
  }

  public close() {
    this.dialogController.cancel();
  }
}

