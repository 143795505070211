import { AppModuleRoot, AppModuleRouteConfig } from "app-modules/app-module-root";
import { PermissionsConfig } from "digiwall-lib";
import { EnumerationTypeCategory } from "digiwall-lib";
import * as breeze from 'breeze-client';
import { AppModule } from "app-modules/constants";
import translationJson from './locales/index';
import * as Constants from "../../constants";

export class ReportsAndKpiRoot extends AppModuleRoot {
  public get name() {
    return AppModule.ReportsAndKpi;
  }

  public get translations(): { [key: string]: object; } {
    return translationJson;
  }

  public get enumerationTypeCategories(): EnumerationTypeCategory[] {
    return [];
  }

  public get enumerationTypeList(): object {
    return {};
  }

  protected get pathName(): string {
    return 'reports-and-kpi';
  }

  protected get routes(): AppModuleRouteConfig[] {
    return [
      {
        before: Constants.RouteName.AppParam,
        route: 'reports/reports&KPI/inputReport',
        name: 'inputReport',
        moduleId: 'reports/input-report/input-report',
        title: 'inputreport.inputreport',
        nav: true,
        settings: {
          group: Constants.MenuType.Reports
        }
      },
      {
        route: 'reports/reports&KPI/pickingReport',
        name: 'pickingReport',
        moduleId: 'reports/picking-report/picking-report',
        title: 'pickingreport.pickingreport',
        nav: true,
        settings: {
          group: Constants.MenuType.Reports
        }
      }
    ];
  }

  protected get resourceNames(): {} {
    return {};
  }

  protected get permissionsConfig(): PermissionsConfig {
    return {};
  }

  public setupModel(metadataStore: breeze.MetadataStore, manager: breeze.EntityManager) { }
}
