import { MobileScreens } from 'app-modules/mobile-screens/generated';
import { FilterQueryOp, Predicate } from "breeze-client";
import { ServiceBase } from "digiwall-lib";
import { Zeus } from 'generated';
import * as Constants from "../../../../constants";
import * as MSConstants from "../../constants";
import { Utils } from "utils/utils";
import { InventoryBaseFloatingBox } from "work-orders/floating-box/inventory/inventory-base-floating-box";
import { BindingEngine, Container } from "aurelia-framework";

export class InventoryStaticRowsFloatingBox extends InventoryBaseFloatingBox<MobileScreens.Model.InventoryWorkOrderStaticRows> {
  private storageService: ServiceBase<Zeus.Web.Model.Storage>;
  private firstRowSelection: string[] = [];
  private lastRowSelection: string[] = [];

  constructor(workOrder: Zeus.Web.Model.WorkOrder, private entity: MobileScreens.Model.InventoryWorkOrderStaticRows = null) {
    super(workOrder, '', new ServiceBase<MobileScreens.Model.InventoryWorkOrderStaticRows>(MSConstants.EntityTypeNames.InventoryWorkOrderStaticRows), 'static-rows');
    if (this.entity != null) {
      this.link.entityAspect.setDetached();
      this.link = entity;
    }
    this.storageService = new ServiceBase<Zeus.Web.Model.Storage>(Constants.EntityTypeNames.Storage);
    this.storageService.gridDataSource.expands = ['locationFormat.locationLevels'];
    this.storageService.gridDataSource.customSelect2Predicates = () => {
      return Predicate.and(
        this.applyUniquenessPredicate(this.workOrder.inventoryLinks as MobileScreens.Model.InventoryWorkOrderStaticRows[], Utils.storagePredicateLimitedToStorageGroup(this.workOrder.limitedToStorageGroup)),
        new Predicate("storageTypeId", FilterQueryOp.Equals, MSConstants.StorageTypes.Static)
      );
    };
  }

  private applyUniquenessPredicate(inventoryEntities: MobileScreens.Model.InventoryWorkOrderStaticRows[], predicate: Predicate = null) {
    return inventoryEntities.reduce((predicate: Predicate, link: any) => {
      if (link.storageId != null) {
        let notEqual = new Predicate('id', FilterQueryOp.NotEquals, link.storageId);
        return predicate ? predicate.and(notEqual) : notEqual;
      }
      return predicate;
    }, predicate);
  }

  public attached() {
    if (this.entity != null) {
      this.setFirstRowSelection();
      this.setLastRowSelection();
    }
    const bindingEngine = Container.instance.get(BindingEngine);
    this.disposables.push(
      bindingEngine.propertyObserver(this.link, 'staticStorageId').subscribe((newVal) => {
        this.link.firstRow = null;
        if (newVal == null) {
          this.firstRowSelection = [];
        } else {
          this.setFirstRowSelection();
        }
      }),
      bindingEngine.propertyObserver(this.link, 'firstRow').subscribe((newVal) => {
        this.link.lastRow = null;
        if (newVal == null) {
          this.lastRowSelection = [];
        } else {
          this.setLastRowSelection();
        }
      }),
      bindingEngine.propertyObserver(this.link, 'lastRow').subscribe((newVal) => {
        if (newVal) {
          this.canSave = true;
        }
      })
    );
  }

  private setFirstRowSelection() {
    if (this.link.staticStorage.locationFormat) {
      const level = this.link.staticStorage.locationFormat.locationLevels.find((x: { level: MSConstants.EnumLocationLevelLevels; }) => x.level == MSConstants.EnumLocationLevelLevels.Row);
      this.firstRowSelection.splice(0);
      this.firstRowSelection.push(...this.rangeToList(level.valueRange));
    }
  }

  private setLastRowSelection() {
    this.lastRowSelection.splice(0);
    this.lastRowSelection.push(...this.firstRowSelection.slice(this.firstRowSelection.indexOf(this.link.firstRow)));
  }

  private rangeToList(range: { split: (arg0: string) => [any, any]; }): string[] {
    const [start, end] = range.split("-");
    if (!start || !end) {
      throw new Error("Invalid range format. Use 'X-Y'.");
    }
    if (!isNaN(start) && !isNaN(end)) {
      // Numeric range
      const min = parseInt(start, 10);
      const max = parseInt(end, 10);
      if (min > max) throw new Error("Start of range must be less than or equal to the end.");
      return Array.from({ length: max - min + 1 }, (_, i) => (min + i).toString());
    } else if (start.length === 1 && end.length === 1 && /^[a-zA-Z]$/.test(start) && /^[a-zA-Z]$/.test(end)) {
      // Alphabetic range
      const min = start.charCodeAt(0);
      const max = end.charCodeAt(0);
      if (min > max) throw new Error("Start of range must be less than or equal to the end.");
      return Array.from({ length: max - min + 1 }, (_, i) => String.fromCharCode(min + i));
    } else {
      throw new Error("Range must be either numeric or alphabetic single characters.");
    }
  }
}
