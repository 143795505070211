import * as MSConstants from 'app-modules/mobile-screens/constants';
import { Router } from 'aurelia-router';
import { CustomLogger, ServiceBase, EntityDetailViewModelBase, EnumerationTypeService } from 'digiwall-lib';
import { autoinject } from 'aurelia-framework';
import * as Constants from '../../../constants';
import * as DTLConstants from '../../drop-to-light/constants';
import * as LPConstants from '../constants';
import { LabelPrinting } from '../generated';
import { MobileScreens } from 'app-modules/mobile-screens/generated';
import { DropToLight } from '../../drop-to-light/generated';
import { Zeus } from '../../../generated';
import { FilterQueryOp, Predicate } from 'breeze-client';
import { AppModuleService } from 'app-modules/app-module-service';
import { AppModuleEnum } from 'app-modules/constants';

@autoinject
export class LabelPrintConfigurationDetail extends EntityDetailViewModelBase<LabelPrinting.Model.LabelPrintConfiguration> {
  public documentTitle: string = "labelprintconfiguration.labelprintconfigurations";
  public ressourceName: string = LPConstants.EntityTypeNames.LabelTemplate;
  public LPConstants = LPConstants;

  private labelPrintConfigurationService: ServiceBase<LabelPrinting.Model.LabelPrintConfiguration>;
  private labelTemplateOrderService: ServiceBase<LabelPrinting.Model.LabelTemplate>;
  private labelTemplatePickService: ServiceBase<LabelPrinting.Model.LabelTemplate>;
  private labelTemplateMobilePickService: ServiceBase<LabelPrinting.Model.LabelTemplate>;
  private workstationService: ServiceBase<DropToLight.Model.Workstation>;
  private cartService: ServiceBase<DropToLight.Model.Workstation>;
  private bayService: ServiceBase<Zeus.Web.Model.Bay>;
  private applicationParameterService: ServiceBase<Zeus.Web.Model.ApplicationParameters>;
  private applicationParameter: Zeus.Web.Model.ApplicationParameters;
  private mobileParametersService: ServiceBase<MobileScreens.Model.MobileParameters>;
  private mobileParameters: MobileScreens.Model.MobileParameters;
  private mobilePrinterService: EnumerationTypeService;

  private hasDTLModule: boolean = false;
  private hasMobileScreensModule: boolean = false;
  private labelPrintConfigOrders: Array<LabelPrinting.Model.LabelPrintConfiguration> = [];
  private labelPrintConfigPicks: Array<LabelPrinting.Model.LabelPrintConfiguration> = [];
  private labelPrintConfigMobilePicks: Array<LabelPrinting.Model.LabelPrintConfiguration> = [];

  private collapsedOrders: boolean = false;
  private collapsedPicks: boolean = false;

  constructor(router: Router, logger: CustomLogger, private appModuleService: AppModuleService) {
    super(router, logger);
    super.initialize(new ServiceBase<LabelPrinting.Model.LabelPrintConfiguration>(LPConstants.EntityTypeNames.LabelPrintConfiguration));
    this.labelPrintConfigurationService = new ServiceBase<LabelPrinting.Model.LabelPrintConfiguration>(LPConstants.EntityTypeNames.LabelPrintConfiguration);
    this.labelTemplateOrderService = new ServiceBase<LabelPrinting.Model.LabelTemplate>(LPConstants.EntityTypeNames.LabelTemplate);
    this.labelTemplatePickService = new ServiceBase<LabelPrinting.Model.LabelTemplate>(LPConstants.EntityTypeNames.LabelTemplate);
    this.labelTemplateMobilePickService = new ServiceBase<LabelPrinting.Model.LabelTemplate>(LPConstants.EntityTypeNames.LabelTemplate);
    this.workstationService = new ServiceBase<DropToLight.Model.Workstation>(DTLConstants.EntityTypeNames.Workstation);
    this.cartService = new ServiceBase<DropToLight.Model.Workstation>(DTLConstants.EntityTypeNames.Workstation);
    this.bayService = new ServiceBase<Zeus.Web.Model.Bay>(Constants.EntityTypeNames.Bay);
    this.bayService.gridDataSource.expands = ['storage'];
    this.applicationParameterService = new ServiceBase<Zeus.Web.Model.ApplicationParameters>(Constants.EntityTypeNames.ApplicationParameters);
    this.mobileParametersService = new ServiceBase<MobileScreens.Model.MobileParameters>(MSConstants.EntityTypeNames.MobileParameters);
    this.hasDTLModule = this.appModuleService.isActive(AppModuleEnum.DropToLight);
    this.hasMobileScreensModule = this.appModuleService.isActive(AppModuleEnum.MobileScreens);
    this.mobilePrinterService = new EnumerationTypeService(LPConstants.EnumerationTypes.LabelPrinter);
  }

  public async activate(params) {
    this.applicationParameter = await this.applicationParameterService.firstEntity(null, ["pickLabelConcept"]);
    if (this.hasDTLModule) {
      this.labelPrintConfigOrders.push(...await this.labelPrintConfigurationService.getEntities(new Predicate("workstationId", FilterQueryOp.NotEquals, null), ['workstation', 'labelTemplate', 'printer']));
      if (this.hasMobileScreensModule) {
        const filteredOrders = this.labelPrintConfigOrders?.filter(x => !x.workstation.isMobileWorkstation) ?? [];
        this.labelPrintConfigOrders = filteredOrders;
        this.workstationService.gridDataSource.customSelect2Predicates = () => {
          return Predicate.and(this.labelPrintConfigOrders.filter(x => x.workstationId != null).map(x => new Predicate("id", FilterQueryOp.NotEquals, x.workstationId)))
            .and(new Predicate("isMobileWorkstation", FilterQueryOp.Equals, false));
        }
        this.mobileParameters = await this.mobileParametersService.firstEntity(null, ["pickLabelConcept"]);
        this.labelPrintConfigMobilePicks.push(...await this.labelPrintConfigurationService.getEntities(new Predicate("workstationId", FilterQueryOp.NotEquals, null), ['workstation', 'labelTemplate', 'printer']));
        this.labelPrintConfigMobilePicks = this.labelPrintConfigMobilePicks?.filter(x => x.workstation.isMobileWorkstation);
        this.cartService.gridDataSource.customSelect2Predicates = () => {
          return Predicate.and(new Predicate("isMobileWorkstation", FilterQueryOp.Equals, true)).and(this.labelPrintConfigMobilePicks.filter(x => x.workstationId != null).map(x => new Predicate("id", FilterQueryOp.NotEquals, x.workstationId)));
        }
        this.mobilePrinterService.gridDataSource.customSelect2Predicates = () => {
          return Predicate.and(this.labelPrintConfigMobilePicks.filter(x => x.printerId != null).map(x => new Predicate("id", FilterQueryOp.NotEquals, x.printerId)));
        }
      } else {
        this.workstationService.gridDataSource.customSelect2Predicates = () => {
          return Predicate.and(this.labelPrintConfigOrders.filter(x => x.workstationId != null).map(x => new Predicate("id", FilterQueryOp.NotEquals, x.workstationId)));
        }
      }
    }
    this.bayService.gridDataSource.customSelect2Predicates = () => {
      return Predicate.and(this.labelPrintConfigPicks.filter(x => x.bayId != null).map(x => new Predicate("id", FilterQueryOp.NotEquals, x.bayId)));
    }
    let activeTemplate: Predicate = new Predicate("activeTemplate", FilterQueryOp.Equals, true);
    this.labelTemplateOrderService.gridDataSource.customSelect2Predicates = () => {
      return new Predicate("labelTypeId", FilterQueryOp.Equals, LPConstants.LabelTypeId.Order).and(activeTemplate);
    }
    this.labelTemplatePickService.gridDataSource.customSelect2Predicates = () => {
      return new Predicate("labelTypeId", FilterQueryOp.Equals, LPConstants.LabelTypeId.Pick).and(activeTemplate);
    }
    this.labelTemplateMobilePickService.gridDataSource.customSelect2Predicates = () => {
      return new Predicate("labelTypeId", FilterQueryOp.Equals, LPConstants.LabelTypeId.Pick).and(activeTemplate);
    }
  }

  public async addConfiguration(configType: number, isMobile: boolean = false) {
    let newConfig = await this.labelPrintConfigurationService.createEntity({ alwaysPrint: true });
    if (configType == LPConstants.LabelTypeId.Order) {
      this.labelPrintConfigOrders.push(newConfig);
    } else if (configType == LPConstants.LabelTypeId.Pick) {
      isMobile ? this.labelPrintConfigMobilePicks.push(newConfig) :
        this.labelPrintConfigPicks.push(newConfig);
    }
  }

  public async removeConfiguration(configuration: LabelPrinting.Model.LabelPrintConfiguration) {
    if (await this.labelPrintConfigurationService.deleteEntities([configuration], true)) {
      //clear all validations for the delete configuration
      //first part of filter is for all binding from html
      //second part of filter is for all binding from an element in html (ui-enumeration-select)
      Array.from(((this.controller as any).bindings as Map<any, any>).keys())
        .filter(b => b.source.overrideContext.bindingContext.id == configuration.id || b.source.overrideContext.bindingContext.entity?.id == configuration.id)
        .forEach(x => {
          this.controller.unregisterBinding(x);
        });
      this.labelPrintConfigPicks.remove(configuration);
      this.labelPrintConfigOrders.remove(configuration);
      this.labelPrintConfigMobilePicks.remove(configuration);
    }
  }

  public async innerCancel(allowNavigateBack?: boolean, silentMode?: boolean, onlyCurrentEntity?: boolean) {
    let result = await super.innerCancel(allowNavigateBack, silentMode, onlyCurrentEntity);
    if (result) {
      this.labelPrintConfigOrders.filter(x => x.id < 0).forEach(x => this.labelPrintConfigOrders.remove(x));
      this.labelPrintConfigPicks.filter(x => x.id < 0).forEach(x => this.labelPrintConfigPicks.remove(x));
      ((this.controller as any).bindings as Map<any, any>).clear(); //clear all validations on detached entities
    }
  }

  private validateLabelConfigs(): boolean {
    let isValid: boolean;
    if (this.labelPrintConfigPicks.filter(x => !x.bayId).length > 0) {
      this.logError(this.i18n.tr("labelprintconfiguration.errors.bayIdRequired"), null);
      isValid = false;
    }
    if (this.labelPrintConfigOrders.filter(x => !x.workstationId).length > 0) {
      this.logError(this.i18n.tr("labelprintconfiguration.errors.workstationIdRequired"), null);
      isValid = false;
    }
    if (this.hasMobileScreensModule && this.labelPrintConfigMobilePicks.filter(x => x.workstationId).length > 0) {
      this.logError(this.i18n.tr("labelprintconfiguration.errors.cartIdRequired"), null);
      isValid = false;
    }
    return isValid
  }

  public override async beforeSave(): Promise<void | boolean> {
    if (!this.validateLabelConfigs())
      return false;
    await super.beforeSave();
  }
}
