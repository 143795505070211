import { HttpClient } from 'aurelia-fetch-client';
import { BindingEngine, autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { CustomLogger, ServiceBase, UIInternal, } from 'digiwall-lib';
import { Zeus } from 'generated';
import * as Constants from '../../../../constants';
import * as RKConstants from '../../constants';
import { FilterQueryOp, Predicate } from 'breeze-client';
import { BaseReport, ReportParameters } from '../base-report/base-report';

@autoinject
export class PickingReport extends BaseReport<PickingReportParameters> {
  protected urlReporting = RKConstants.Application.PickingReport;

  private workOrderCustomerPOService: ServiceBase<Zeus.Web.Model.WorkOrder>;
  private workOrderCustomerNbService: ServiceBase<Zeus.Web.Model.WorkOrder>;

  constructor(logger: CustomLogger, http: HttpClient, i18n: I18N, bindingEngine: BindingEngine) {
    super(logger, http, i18n, bindingEngine);
    this.workOrderCustomerPOService = new ServiceBase<Zeus.Web.Model.WorkOrder>(Constants.EntityTypeNames.WorkOrder);
    this.workOrderCustomerNbService = new ServiceBase<Zeus.Web.Model.WorkOrder>(Constants.EntityTypeNames.WorkOrder);
    this.parameters = new PickingReportParameters();
  }

  public async activate(params) {
    await super.activate(params);
    this.workOrderCustomerPOService.gridDataSource.customSelect2Predicates = () => Predicate.and(this.getWoPredicate(), new Predicate("pickingCustomerPO", FilterQueryOp.NotEquals, null));
    this.workOrderCustomerNbService.gridDataSource.customSelect2Predicates = () => Predicate.and(this.getWoPredicate(), new Predicate("pickingCustomerNumber", FilterQueryOp.NotEquals, null));
  }

  protected getWoPredicate(): Predicate {
    return Predicate.and(...[
      new Predicate("workOrderTypeId", FilterQueryOp.Equals, Constants.WorkOrderType.Picking),
      this.getWoStatusPredicate("workOrderStatusId")
    ]);
  }

  public async attached() {
    this.disposables.push(
      this.bindingEngine.propertyObserver(this.parameters, "workOrderCustomerPoId").subscribe(async (newValue, oldValue) => {
        if (!this.isFetchingParams && newValue != null && newValue != oldValue) {
          this.isFetchingParams = true;
          this.parameters.workOrderCustomerPoId = null;
          this.parameters.workOrderCustomerPo = await this.workOrderService.getEntityById(newValue);
          this.parameters.workOrderCustomerPoId = newValue;
          UIInternal.nextTick(() => { this.isFetchingParams = false });
        }
      }),
      this.bindingEngine.propertyObserver(this.parameters, "workOrderCustomerNbId").subscribe(async (newValue, oldValue) => {
        if (!this.isFetchingParams && newValue != null && newValue != oldValue) {
          this.isFetchingParams = true;
          this.parameters.workOrderCustomerNbId = null;
          this.parameters.workOrderCustomerNb = await this.workOrderService.getEntityById(newValue);
          this.parameters.workOrderCustomerNbId = newValue;
          UIInternal.nextTick(() => { this.isFetchingParams = false });
        }
      }),
    );
    super.attached();
  }

  protected override replacerOfParameters(key, val) {
    if (key == 'workOrderCustomerPo' || key == 'workOrderCustomerNb') return null;
    return super.replacerOfParameters(key, val);
  }
}

class PickingReportParameters extends ReportParameters {
  workOrderCustomerPoId: number | null;
  workOrderCustomerPo: Zeus.Web.Model.WorkOrder;
  workOrderCustomerNbId: number | null;
  workOrderCustomerNb: Zeus.Web.Model.WorkOrder;
}
