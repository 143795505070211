import { computedFrom } from "aurelia-binding";
import { autoinject } from "aurelia-framework";
import { bindable, customElement, viewResources } from "aurelia-templating";
import { Zeus } from "generated";
import * as Constants from '../../../constants';
import { InventoryCycleCount } from "./inventory-cycle-count";
import { InventoryArticleActions } from "./inventory-article-actions";
import { InventoryStorageActions } from "./inventory-storage-actions";
import { InventoryTrayActions } from "./inventory-tray-actions";
import { InventoryStaticBays } from "app-modules/mobile-screens/inventory/inventory-static-bays/inventory-static-bays";
import { InventoryStaticRows } from "../../../app-modules/mobile-screens/inventory/inventory-static-rows/inventory-static-rows";
import * as MSConstants from '../../../app-modules/mobile-screens/constants';

@autoinject
@customElement('inventory-actions')
@viewResources(InventoryCycleCount, InventoryArticleActions, InventoryStorageActions, InventoryTrayActions, InventoryStaticRows, InventoryStaticBays)
export class InventoryActions {
  @bindable
  private isModifiable: boolean;
  @bindable
  private workOrder: Zeus.Web.Model.WorkOrder;
  @bindable
  private saveWorkOrder: () => Promise<boolean>;
  @bindable
  private onLoaded: () => void;

  @computedFrom('workOrder.inventoryTargetId')
  private get isTargetArticle(): boolean {
    return this.workOrder.inventoryTargetId == Constants.InventoryTarget.Article;
  }
  @computedFrom('workOrder.inventoryTargetId')
  private get isTargetArticleLocations(): boolean {
    return this.workOrder.inventoryTargetId == Constants.InventoryTarget.ArticleLocations;
  }

  @computedFrom('workOrder.inventoryTargetId')
  private get isTargetStorage(): boolean {
    return this.workOrder.inventoryTargetId == Constants.InventoryTarget.Storage;
  }

  @computedFrom('workOrder.inventoryTargetId')
  private get isTargetTrayContainer(): boolean {
    return this.workOrder.inventoryTargetId == Constants.InventoryTarget.TrayContainer;
  }

  @computedFrom('workOrder.inventoryTargetId')
  private get isTargetStaticRows(): boolean {
    return this.workOrder.inventoryTargetId == MSConstants.InventoryTarget.StaticRows;
  }

  @computedFrom('workOrder.inventoryTargetId')
  private get isTargetCycleCount(): boolean {
    return this.workOrder.inventoryTargetId == Constants.InventoryTarget.CycleCount;
  }

  @computedFrom('workOrder.inventoryTargetId')
  private get isTargetStaticBays(): boolean {
    return this.workOrder.inventoryTargetId == MSConstants.InventoryTarget.StaticBays;
  }
}
