import { Zeus } from './generated.d';
import { I18N } from 'aurelia-i18n';
import * as Constants from "./constants";
import { BreezeModel, CustomLogger, Datacontext, Language, TranslatedPropertyHelper } from "digiwall-lib";
import { Container, autoinject } from 'aurelia-framework';
import * as breeze from 'breeze-client';
import { AppModuleService } from './app-modules/app-module-service';

@autoinject
export default class ZeusBreezeModel extends BreezeModel {

  constructor(i18n: I18N, logger: CustomLogger, datacontext: Datacontext, translatedPropertyHelper: TranslatedPropertyHelper) {
    super(i18n, logger, datacontext, translatedPropertyHelper);
  }

  configureInitializerAndCtor(metadataStore: breeze.MetadataStore, manager: breeze.EntityManager) {
    metadataStore.registerEntityTypeCtor(Constants.EntityTypeNames.Locality, null, (entity: any) => {
      Object.defineProperty(entity, 'fullLocality', {
        configurable: true,
        get: () => {
          return `${entity.zipCode} ${entity.name._translation}`;
        }
      });
    });

    metadataStore.registerEntityTypeCtor("User", function () {
      this.permissions = '';
    }, null);

    metadataStore.registerEntityTypeCtor(Constants.EntityTypeNames.LocationFormat, null, (entity: Zeus.Web.Model.LocationFormat) => {
      Object.defineProperty(entity, 'displayName', {
        configurable: true,
        get: () => {
          return entity.name;
        }
      });
    });
    metadataStore.registerEntityTypeCtor(Constants.EntityTypeNames.Article, null, (entity: Zeus.Web.Model.Article) => {
      Object.defineProperty(entity, 'displayName', {
        configurable: true,
        get: () => {
          if (entity.description._translation != null && entity.description._translation.length > 0) {
            return `${entity.reference} - ${entity.description._translation}`;
          }
          return entity.reference;
        }
      });
    });
    metadataStore.registerEntityTypeCtor(Constants.EntityTypeNames.StorageGroup, null, async (entity: Zeus.Web.Model.StorageGroup) => {
      Object.defineProperty(entity, 'displayName', {
        configurable: true,
        get: () => {
          let text = "";
          if (entity.parentStorageGroup != null) text += `${entity.parentStorageGroup.name} - `;
          text += entity.name;
          return text;
        }
      });
    });
    metadataStore.registerEntityTypeCtor(Constants.EntityTypeNames.Bay, null, (entity: Zeus.Web.Model.Bay) => {
      Object.defineProperty(entity, 'displayName', {
        get: () => {
          return (entity.storage?.name ?? '') + " - " + this.i18N.tr("bay.bay") + " " + entity.bayNumber
        }
      });
    });
    metadataStore.registerEntityTypeCtor(Constants.EntityTypeNames.WOMessageType, null, (entity: Zeus.Web.Model.WOMessageType) => {
      Object.defineProperty(entity, 'displayName', {
        get: () => {
          return entity.orderSubtype?.denomination?._translation ?? entity.workOrderType?.denomination?._translation;
        }
      });
    });
    metadataStore.registerEntityTypeCtor(Constants.EntityTypeNames.LocationForInput, function () {
      this.carrier = '';
      this.carrierType = '';
    });
    metadataStore.registerEntityTypeCtor(Constants.EntityTypeNames.LocationLevel, function () {
      this.min = null;
      this.max = null;
    });
    Container.instance.get(AppModuleService).setupModel(metadataStore, manager);
  }
}
