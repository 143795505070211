import { AppModuleRoot, AppModuleRouteConfig } from "app-modules/app-module-root";
import { Actions, PermissionsConfig } from "digiwall-lib";
import translationJson from './locales/index';
import { EnumerationTypeCategory } from "digiwall-lib";
import { Container } from "aurelia-dependency-injection";
import * as breeze from 'breeze-client';
import { AppModule } from "app-modules/constants";
import * as ConvConstants from "./constants";
import { Permissions, RoleNames, MenuType, RouteName } from "../../constants";
import * as ReceptionConstants from "../../reception/constants";
import { ReceptionActivator } from "reception/reception-activator";

export class ConveyorRoot extends AppModuleRoot {
  public get name() {
    return AppModule.Conveyor;
  }

  public get translations(): { [key: string]: object; } {
    return this.mergeTranslations([
      Container.instance.get(ReceptionActivator).translations,
      translationJson
    ]);
  }

  public get enumerationTypeCategories(): EnumerationTypeCategory[] {
    return [];
  }

  public get enumerationTypeList(): object {
    return Container.instance.get(ReceptionActivator).enumerationTypeList;
  }

  protected get pathName(): string {
    return 'conveyor';
  }

  protected get routes(): AppModuleRouteConfig[] {
    return [
      ...Container.instance.get(ReceptionActivator).getRoutes(),
      {
        after: RouteName.ProcessMonitoring,
        route: "bin-overview",
        name: 'bin-overview/bin-overview',
        moduleId: "bin-overview/bin-overview",
        title: 'binoverview.binoverview',
        nav: true,
        settings: {
          group: MenuType.WorkOrders,
          resource: ConvConstants.Permissions.BinOverview,
          action: Actions.Read
        }
      }
    ];
  }

  protected get resourceNames(): {} {
    return ConvConstants.EntityTypeNames.ResourceNames;
  }

  protected get permissionsConfig(): PermissionsConfig {
    return {
      perContext: [
        { context: ReceptionConstants.Permissions.Reception, qualifiedName: [RoleNames.Mobile] },
        { context: ReceptionConstants.Permissions.ReceptionParameters, isCrud: true, qualifiedName: [MenuType.ApplicationParameters, 'applicationparameters.receptionParameters'] },
        { context: ConvConstants.Permissions.BinOverview, qualifiedName: ['conveyorconfiguration.conveyorconfiguration', 'binoverview.binoverview'] },
        { context: ReceptionConstants.Permissions.StorageParameter, qualifiedName: [RoleNames.Mobile, 'permission.storageParameter'] },
      ],
      permissionNames: {
        'work-order': {
          [ConvConstants.Permissions.LaunchPickBin]: 'launchinbin.permission'
        },
        [ReceptionConstants.Permissions.Reception]: {
          [Permissions.Access]: ReceptionConstants.RoleNames.Reception
        }
      }
    };
  }

  public setupModel(metadataStore: breeze.MetadataStore, manager: breeze.EntityManager) {
  }
}
