import { customElement } from "aurelia-templating";
import { Container, TaskQueue } from "aurelia-framework";
import { InventoryBaseActions } from "work-orders/article-to-actions/inventory/inventory-base-actions";
import { MobileScreens } from "../../generated";
import * as MSConstants from "../../constants";
import { ServiceBase } from "digiwall-lib";
import { InventoryStaticBaysFloatingBox } from "../floating-box/inventory-static-bays-floating-box";
import { InventoryBaseFloatingBox } from "work-orders/floating-box/inventory/inventory-base-floating-box";

@customElement('inventory-static-bays')
export class InventoryStaticBays extends InventoryBaseActions<MobileScreens.Model.InventoryWorkOrderStaticBays> {
  constructor() {
    super(new ServiceBase<MobileScreens.Model.InventoryWorkOrderStaticBays>(MSConstants.EntityTypeNames.InventoryWorkOrderStaticBays));
    let taskQueue = Container.instance.get(TaskQueue);
    this.disposables.push(
      this.bindingEngine.expressionObserver(this, 'workOrder.limitedToStorageGroupId').subscribe(() => {
        // Refresh calculation of article links location counts
        if (this.workOrder.inventoryLinks?.length > 0) {
          let links = this.workOrder.inventoryLinks.splice(0);
          taskQueue.queueMicroTask(() => this.workOrder.inventoryLinks.push(...links));
        }
      })
    );
  }

  protected async editLink(entity: MobileScreens.Model.InventoryWorkOrderStaticBays) {
    if (this.workOrder.id <= 0) {
      let saveOk = await this.saveWorkOrder();
      if (saveOk == false) {
        return;
      }
    }
    this.box.showFloatingBox(new InventoryStaticBaysFloatingBox(this.workOrder, entity)).whenClosed(async response => {
      if (false == response.wasCancelled && response.output != null) {
        const entity = await this.service.getEntityById(response.output.id, ...this.getExpands(true));
        this.setNumberOfLocations(entity);
      }
    });
  }

  protected getExpands(withLocations: boolean = false): string[] {
    let expands = ['staticStorage.locationFormat.locationLevels'];
    if (withLocations) {
      expands.push('staticStorage.locations');
    }
    return expands;
  }

  protected setNumberOfLocations(entity: MobileScreens.Model.InventoryWorkOrderStaticBays): MobileScreens.Model.InventoryWorkOrderStaticBays {
    if (entity.staticStorage.locations?.length > 0) {
      (entity as any).nbLocationSelected = entity.staticStorage.locations
        .filter(x => (x as MobileScreens.Model.StaticRackLocation).row == entity.row)
        .filter(x => (x as MobileScreens.Model.StaticRackLocation).bay >= entity.firstBay)
        .filter(x => (x as MobileScreens.Model.StaticRackLocation).bay <= entity.lastBay)
        .length;
    }
    return entity;
  }

  protected getFloatingBoxInstance(): InventoryBaseFloatingBox<MobileScreens.Model.InventoryWorkOrderStaticBays> {
    return new InventoryStaticBaysFloatingBox(this.workOrder);
  }
}
