import { Router } from 'aurelia-router';
import { EntityDetailViewModelBase, CustomLogger, EditingModeEnum, ServiceBase, EnumerationTypeService, changeRoutesNav, UIInternal, DialogBoxViewModel } from 'digiwall-lib';
import { autoinject, BindingEngine, computedFrom } from 'aurelia-framework';
import * as Constants from '../../../constants';
import * as MSConstants from '../constants';
import { MobileScreens } from '../generated'

@autoinject
export class ApplicationParameterDetail extends EntityDetailViewModelBase<MobileScreens.Model.MobileParameters> {
  public ressourceName: string = Constants.EntityTypeNames.ApplicationParameters;

  public staticStoragePickValidationTypeService: EnumerationTypeService;
  public staticStorageInventoryValidationTypeService: EnumerationTypeService;
  public binValidationTypeService: EnumerationTypeService;

  constructor(router: Router, logger: CustomLogger, private bindingEngine: BindingEngine) {
    super(router, logger);
    super.initialize(new ServiceBase<MobileScreens.Model.MobileParameters>(MSConstants.EntityTypeNames.MobileParameters));

    this.staticStoragePickValidationTypeService = new EnumerationTypeService(MSConstants.EnumerationTypes.StaticStoragePickValidationType);
    this.staticStorageInventoryValidationTypeService = new EnumerationTypeService(MSConstants.EnumerationTypes.StaticStorageInventoryValidationType);
    this.binValidationTypeService = new EnumerationTypeService(MSConstants.EnumerationTypes.BinValidationType);
  }

  @computedFrom("entity.id")
  public get documentTitle() {
    return this.i18n.tr("mobileparameters.mobileParameters");
  }

  @computedFrom("entity.id")
  public get ribbonHeaderText() {
    return this.i18n.tr("applicationparameters.applicationparameters") + " : " + this.documentTitle;
  }

  public async activate() {
    this.editingMode = EditingModeEnum.Update;
    this.entity = await this.service.firstEntity(null, ['staticStoragePickValidationType', 'binValidationType', 'staticStorageInventoryValidationType']);
    this.controller.addObject(this.entity);
  }
}
