import { Reception } from './../reception/generated.d';
import { ApiService } from '../external-src/api.service';
import { Predicate, FilterQueryOp, EntityState } from 'breeze-client';
import { CustomLogger, ServiceBase, EntityDetailViewModelBase, EditingModeEnum, EnumerationTypeService, FileFlow, Actions, AuthService } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { Container, autoinject, BindingEngine, computedFrom } from 'aurelia-framework';
import { Zeus } from "../generated";
import * as Constants from '../constants';
import ZeusBreezeModel from 'model';
import { AppModuleService } from 'app-modules/app-module-service';
import { ArticleDetailLocations } from 'articles/article-detail-helpers/article-detail-locations';
import { BindingSignaler } from 'aurelia-templating-resources';


@autoinject
export class MissingDataInputWorkOrderLineDetail extends EntityDetailViewModelBase<Zeus.Web.Model.MissingDataInputWorkOrderLine> {
  public ressourceName: string = Constants.EntityTypeNames.MissingDataInputWorkOrderLine;
  private Constants: any = Constants;

  public selectedLanguageCode = 'en';
  public locationsOnSameSite = {};

  public articleUnitOfMeasureSizeService: ServiceBase<Zeus.Web.Model.ArticleUnitOfMeasureSize>;
  public articleVolumeConfigService: ServiceBase<Zeus.Web.Model.ArticleVolumeConfig>;
  public articleStorageGroupService: ServiceBase<Zeus.Web.Model.ArticleStorageGroup>;
  public storageGroupService: ServiceBase<Zeus.Web.Model.StorageGroup>;
  public storageVolumeService: ServiceBase<Zeus.Web.Model.StorageVolume>;
  public applicationParameterService: ServiceBase<Zeus.Web.Model.ApplicationParameters>;
  public languagePriorityService: ServiceBase<Zeus.Web.Model.LanguagePriority>;
  public receptionParametersFieldServiceService: ServiceBase<Reception.Model.ReceptionParametersField>;

  public unitOfMeasuresService: EnumerationTypeService;
  public unitOfMeasuresVolumeConfigurationService: EnumerationTypeService;
  private articleRotationLevelTypeService: EnumerationTypeService;
  private storageLocationTypeService: EnumerationTypeService;
  public storageStrategyService: EnumerationTypeService;

  public articleStorageGroup: Zeus.Web.Model.ArticleStorageGroup;
  public fetchedUnitOfMeasureSizes: Zeus.Web.Model.ArticleUnitOfMeasureSize[];
  private defaultInputAlgo: Zeus.Web.Model.InputAlgorithm;

  private articleLocationsView: ArticleDetailLocations;

  private EntityState: EntityState = EntityState;
  private EnumerationTypes = Constants.EnumerationTypes;

  private receptionParametersFields: Reception.Model.ReceptionParametersField[] = [];

  constructor(router: Router, logger: CustomLogger, private bindingEngine: BindingEngine, private apiService: ApiService, private appModuleService: AppModuleService, private signaler: BindingSignaler) {
    super(router, logger);
    super.initialize(new ServiceBase<Zeus.Web.Model.MissingDataInputWorkOrderLine>(Constants.EntityTypeNames.MissingDataInputWorkOrderLine));

    this.articleUnitOfMeasureSizeService = new ServiceBase<Zeus.Web.Model.ArticleUnitOfMeasureSize>(Constants.EntityTypeNames.ArticleUnitOfMeasureSize);
    this.articleUnitOfMeasureSizeService.gridDataSource.expands = ['unitOfMeasure'];
    this.articleStorageGroupService = new ServiceBase<Zeus.Web.Model.ArticleStorageGroup>(Constants.EntityTypeNames.ArticleStorageGroup);
    this.storageGroupService = new ServiceBase<Zeus.Web.Model.StorageGroup>(Constants.EntityTypeNames.StorageGroup);
    this.articleVolumeConfigService = new ServiceBase<Zeus.Web.Model.ArticleVolumeConfig>(Constants.EntityTypeNames.ArticleVolumeConfig);
    this.articleVolumeConfigService.gridDataSource.expands = ['storageVolume.volumeType', 'unitOfMeasure'];
    this.storageVolumeService = new ServiceBase<Zeus.Web.Model.StorageVolume>(Constants.EntityTypeNames.StorageVolume);
    this.storageVolumeService.gridDataSource.expands = ['volumeType'];

    this.unitOfMeasuresService = new EnumerationTypeService(Constants.EnumerationTypes.UnitOfMeasure);
    this.unitOfMeasuresService.gridDataSource.customSelect2Predicates = () => {
      return this.entity.woLine.article.unitOfMeasures.reduce((predicate: Predicate, uom) => {
        // Ignore empty selects
        if (uom.unitOfMeasureId == null) {
          return predicate;
        }
        let uomPredicate = new Predicate('id', FilterQueryOp.NotEquals, uom.unitOfMeasureId);
        return predicate ? predicate.and(uomPredicate) : uomPredicate;
      }, null);
    };

    this.unitOfMeasuresVolumeConfigurationService = new EnumerationTypeService(Constants.EnumerationTypes.UnitOfMeasure);

    this.articleRotationLevelTypeService = new EnumerationTypeService(Constants.EnumerationTypes.ArticleRotationLevel);

    this.storageLocationTypeService = new EnumerationTypeService(Constants.EnumerationTypes.StorageLocationType);

    this.applicationParameterService = new ServiceBase<Zeus.Web.Model.ApplicationParameters>(Constants.EntityTypeNames.ApplicationParameters);

    this.storageStrategyService = new EnumerationTypeService(Constants.EnumerationTypes.StorageStrategy);

    this.languagePriorityService = new ServiceBase<Zeus.Web.Model.LanguagePriority>(Constants.EntityTypeNames.LanguagePriority);
    this.receptionParametersFieldServiceService = new ServiceBase<Reception.Model.ReceptionParametersField>(Constants.EntityTypeNames.ReceptionParametersField);

  }

  public async activate(params) {
    const id = params.param1;
    this.service.manager.clear();
    this.editingMode = EditingModeEnum.Update;
    this.entity = await this.service.getEntityById(id, 'woLine.article.unitOfMeasures.unitOfMeasure', 'woLine.article.articleVolumeConfigs.storageVolume.volumeType', 'woLine.article.articleVolumeConfigs.unitOfMeasure',
      'woLine.article.articleStorageGroups.storageStrategy'
    );
    await this.articleStorageGroupService.getEntities(null, ['storageLocationType1', 'storageLocationType2', 'storageLocationType3',
      'storageGroup.storageGroupType', 'articleRotationLevel', 'sortingLocation', 'inputAlgorithm', 'storageStrategy'], { articleId: this.entity.woLine.articleId })

    this.entity.woLine.article.articleVolumeConfigs.sort((a, b) => { return a.unitOfMeasure.denomination._translation > b.unitOfMeasure.denomination._translation ? 1 : -1 });
    this.articleUnitOfMeasureSizeService.gridDataSource.queryParameters = { articleId: this.entity.id, withoutUsedForStockCount: true };
    this.unitOfMeasuresVolumeConfigurationService.gridDataSource.queryParameters = { category: Constants.EnumerationTypes.UnitOfMeasure, articleId: this.entity.id };

    const predicate = Predicate.or(
      new Predicate("check", FilterQueryOp.Equals, true),
      new Predicate("edit", FilterQueryOp.Equals, true)
    );
    this.receptionParametersFields.splice(0);
    this.receptionParametersFields.push(...await this.receptionParametersFieldServiceService.getEntities(predicate));
    this.articleStorageGroup = this.entity.woLine.article.articleStorageGroups.find(x => x.default);
    this.controller.addObject(this.entity);

    this.selectedLanguageCode = this.i18n.getLocale();

    new ServiceBase<Zeus.Web.Model.InputAlgorithm>(Constants.EntityTypeNames.InputAlgorithm)
      .firstEntity(new Predicate('isDefault', FilterQueryOp.Equals, true))
      .then(defaultAlgo => this.defaultInputAlgo = defaultAlgo);
  }

  @computedFrom('entity.articleLocations.length')
  private get cantChangeBaseUom(): boolean {
    return this.entity.woLine.article.articleLocations?.length > 0 && this.entity.woLine.article.articleLocations.some(l => [l.currentQuantityInLocation, l.lastInventoryQuantity, l.reservedInputQuantity, l.reservedPickingQuantity].some(qty => qty > 0));
  }

  @computedFrom('entity.id')
  private get readOnlyMode(): boolean {
    return false == Container.instance.get(AuthService).checkAccess(Constants.Permissions.InputOrdersMissingData, Actions.Update);
  }

  @computedFrom('entity.id')
  private get canEditUom(): boolean {
    return !this.readOnlyMode && (this.receptionParameterCanEdited(Constants.ReceptionParametersFieldName.BaseUOMWeight) || this.receptionParameterCanEdited(Constants.ReceptionParametersFieldName.BaseUOMDimensions));
  }

  @computedFrom('entity.id')
  private get baseUomWarning(): boolean {
    return this.entity.uomDepthWarning || this.entity.uomHeightWarning || this.entity.uomWeightWarning || this.entity.uomWidthWarning;
  }

  private receptionParameterExists(fieldName: string): boolean {
    return this.receptionParametersFields.filter(x => x.fieldName.trim().toLowerCase() == fieldName.trim().toLowerCase()).length > 0;
  }

  private receptionParameterCanEdited(fieldName: string): boolean {
    return this.receptionParametersFields.find(x => x.fieldName.trim().toLowerCase() == fieldName.trim().toLowerCase())?.edit;
  }

  @computedFrom('entity.id')
  public get documentTitle() {
    return this.entity.woLine.article.reference;
  }

  public async beforeSave() {
    let isValid: boolean;

    let kvp = this.entity.woLine.article.articleVolumeConfigs.map(artVol => artVol.storageVolumeId?.toString() + "-" + artVol.unitOfMeasureId?.toString());
    if (kvp.some((elem, index) => index != kvp.lastIndexOf(elem))) {
      this.logError(this.i18n.tr('articlevolumeconfig.uomVolumeMustBeUnique'), null);
      isValid = false;
    }
    if (isValid) {
      this.articleLocationsView?.beforeSaveArticle();
    }


    if (!this.entity.woLine.article.unitOfMeasures.some(x => x.usedForStockCount)) {
      this.logError(this.i18n.tr('articleunitofmeasuresize.baseUOMError'), null);
      isValid = false;
    }

    if (!this.entity.woLine.article.unitOfMeasures.some(x => x.defaultInputUOM)) {
      this.logError(this.i18n.tr('articleunitofmeasuresize.defaultInputUOMError'), null);
      isValid = false;
    }

    if (this.entity.woLine.article.unitOfMeasures.some(x => !x.usedForStockCount && x.quantityInStockCountUOM <= 1)) {
      this.logError(this.i18n.tr('articleunitofmeasuresize.notBaseUomQtyError'), null);
      isValid = false;
    }

    return isValid;
  }

  public async attached() {
    this.entity.woLine.article.unitOfMeasures.forEach(unitOfMeasure => {
      this.observableUnitOfMeasure(unitOfMeasure);
    });
  }

  private observableUnitOfMeasure(uom: Zeus.Web.Model.ArticleUnitOfMeasureSize) {
    this.disposables.push(
      this.bindingEngine.propertyObserver(uom, "defaultInputUOM").subscribe((newValue, oldValue) => {
        if (newValue) {
          this.entity.woLine.article.unitOfMeasures.filter(x => x.id != uom.id).forEach(x => {
            x.defaultInputUOM = false;
          });
        }
      }),
      this.bindingEngine.propertyObserver(uom, "usedForStockCount").subscribe((newValue, oldValue) => {
        if (newValue) {
          this.entity.woLine.article.unitOfMeasures.filter(x => x.id != uom.id).forEach(x => {
            x.usedForStockCount = false;
          });
          uom.quantityInStockCountUOM = 1;
        }
      })
    );
  }

  //#region Unit Of Measure
  @computedFrom('entity.unitOfMeasures.length', 'entity.entityAspect.entityState')
  private get canRemove(): boolean {
    return this.entity.woLine.article.unitOfMeasures?.filter(uom => !uom.entityAspect.entityState.isAdded()).length > 1;
  }

  public async removeUnitOfMeasure(unitOfMeasureSize: Zeus.Web.Model.ArticleUnitOfMeasureSize) {
    // Cannot delete last unit of measure
    if (this.entity.woLine.article.unitOfMeasures?.length == 1) {
      return;
    }
    let volumeConfigsToRemove = this.entity.woLine.article.articleVolumeConfigs.filter(vc => vc.unitOfMeasureId == unitOfMeasureSize.unitOfMeasureId);
    let deleted = true;

    if (unitOfMeasureSize.entityAspect.entityState.isAdded()) {
      unitOfMeasureSize.entityAspect.setDetached();
    } else {
      deleted = await this.articleUnitOfMeasureSizeService.deleteEntities([unitOfMeasureSize], true);
    }

    if (false === deleted) {
      return;
    }
    volumeConfigsToRemove.forEach(vc => this.removeArticleVolumeConfig(vc, true));

    if (null == this.entity.woLine.article.unitOfMeasures.find(uom => uom.usedForStockCount)) {
      this.entity.woLine.article.unitOfMeasures[0].usedForStockCount = true;
    }
    if (null == this.entity.woLine.article.unitOfMeasures.find(uom => uom.defaultInputUOM)) {
      this.entity.woLine.article.unitOfMeasures[0].defaultInputUOM = true;
    }
  }

  public async addUnitOfMeasure() {
    let newUOM = await this.articleUnitOfMeasureSizeService.createEntity({ unitOfMeasureId: null });
    this.observableUnitOfMeasure(newUOM);
    if (this.entity.woLine.article.unitOfMeasures.length == 0) {
      newUOM.defaultInputUOM = true;
      newUOM.usedForStockCount = true;
      newUOM.quantityInStockCountUOM = 1;
    }
    newUOM.articleId = this.entity.woLine.article.id;
  }
  //#endregion

  //#region Volume Config
  public async addArticleVolumeConfig() {
    await this.articleVolumeConfigService.createEntity({ articleId: this.entity.woLine.article.id });
  }

  public async removeArticleVolumeConfig(volumeConfig: Zeus.Web.Model.ArticleVolumeConfig, silent: boolean = false) {
    if (volumeConfig.entityAspect.entityState.isAdded()) {
      volumeConfig.entityAspect.setDetached();
    } else {
      await this.articleVolumeConfigService.deleteEntities([volumeConfig], true, null, silent);
    }
  }

  public async setDefaultVolumeConfig(volumeConfig: Zeus.Web.Model.ArticleVolumeConfig) {
    this.entity.woLine.article.articleVolumeConfigs
      .filter(x => x.id != volumeConfig.id && x.unitOfMeasureId == volumeConfig.unitOfMeasureId)
      .forEach(x => x.default = false);
  }
  //#endregion

  //#region Picture
  @computedFrom("entity.pictureUrl")
  public get currentLogo(): string {
    return (this.entity.woLine.article.pictureUrl ? this.entity.woLine.article.pictureUrl : Constants.Pictures.NoPictureContent);
  }

  public deletePicture() {
    this.entity.woLine.article.pictureUrl = null;
    this.entity.woLine.article.pictureCreationDate = null;
    this.signaler.signal('pictureUpdated');
  }

  public async uploadPicture(file: FileFlow) { }

  public async pictureUploaded(fileUploaded: FileFlow) {
    this.entity.woLine.article.pictureUrl = fileUploaded.url;
    this.entity.woLine.article.pictureCreationDate = new Date();
    this.signaler.signal('pictureUpdated');
  }
  //#endregion


}
