import { HttpClient } from 'aurelia-fetch-client';
import { BindingEngine, computedFrom, Disposable } from "aurelia-binding";
import { autoinject } from "aurelia-framework";
import { bindable, customElement } from "aurelia-templating";
import { EntityState, EntityStateSymbol, FilterQueryOp, Predicate } from "breeze-client";
import { Actions, AuthService, Box, ServiceBase, UIInternal } from "digiwall-lib";
import { Zeus } from "generated";
import { InputPickFloatingBox, InputPickFloatingBoxViewMode } from "work-orders/floating-box/input-pick-floating-box";
import * as Constants from '../../constants';
import { WorkOrderUtils } from 'utils/work-order-utils';
import { Endpoints } from 'endpoints';
import { AppModuleEnum } from 'app-modules/constants';
import { AppModuleService } from 'app-modules/app-module-service';
import * as BConstants from 'app-modules/borrowing/constants';

@autoinject
@customElement('input-pick-actions')
export class InputPickActions {
  @bindable public workOrder: Zeus.Web.Model.WorkOrder;
  @bindable private saveWorkOrder: () => Promise<boolean>;
  @bindable private onLoaded: () => void;

  private articleToActionService: ServiceBase<Zeus.Web.Model.ArticleToAction>;
  private locationForInputPickingService: ServiceBase<Zeus.Web.Model.LocationForInputPicking>;

  private articleToActions: Zeus.Web.Model.ArticleToAction[] = [];
  private workOrderStateListener: Disposable;
  private cancelledNumber = 0;
  public Constants = Constants;

  constructor(private box: Box, private authService: AuthService, bindingEngine: BindingEngine, private httpClient: HttpClient,
    private appModuleService: AppModuleService) {
    this.articleToActionService = new ServiceBase<Zeus.Web.Model.ArticleToAction>(Constants.EntityTypeNames.ArticleToAction);
    this.locationForInputPickingService = new ServiceBase<Zeus.Web.Model.LocationForInputPicking>(Constants.EntityTypeNames.LocationForInputPicking);
    // Every time the workOrder is saved (e.g. on state change), reload the articleToActions
    // This is also called on first attached
    this.articleToActionService.gridDataSource.predicates = new Predicate('actionWorkOrderId', FilterQueryOp.Equals, 0);
    this.workOrderStateListener = bindingEngine.expressionObserver(this, 'workOrder.entityAspect.entityState')
      .subscribe((state: EntityStateSymbol) => {
        console.debug("InputPickActions - state ", state);
        if (state == EntityState.Unchanged) {
          this.reload();
        } else {
          console.warn("InputPickActions onreload not triggered");
        }
      });
  }

  private async reload() {
    this.articleToActionService.gridDataSource.predicates = new Predicate('actionWorkOrderId', FilterQueryOp.Equals, this.workOrder.id);
    this.articleToActionService.gridDataSource.expands = ['article.articleVolumeConfigs', 'article.unitOfMeasures.unitOfMeasure',
      'limitedToStorageGroup', 'unitOfMeasure', 'articleStorageGroupForParameter.inputAlgorithm'];
    await this.loadCarrier();
    if (this.onLoaded) {
      this.onLoaded();
    }
  }

  private async afterLoad(datas: Zeus.Web.Model.ArticleToAction[]) {
    await Promise.all(
      datas.map(async ata => {
        await this.locationForInputPickingService.getEntities(
          Predicate.and(
            new Predicate("articleToActionId", FilterQueryOp.Equals, ata.id),
            Predicate.or(
              new Predicate("batchProcessId", FilterQueryOp.Equals, null),
              new Predicate("batchProcess.executionStatusId", FilterQueryOp.Equals, Constants.BatchProccesExecutionStatus.InProgress),
              Predicate.and(
                new Predicate("batchProcess.executionStatusId", FilterQueryOp.NotEquals, Constants.BatchProccesExecutionStatus.InProgress),
                new Predicate("reallyActionQuantity", FilterQueryOp.NotEquals, null),
              )
            )
          ), ['location.defaultStorageVolume.volumeType', 'location.alternateLocationVolumes.storageVolume.volumeType'])
      })
    );
    await this.loadCarrier(datas);
  }

  private detached() {
    this.workOrderStateListener?.dispose();
  }

  @computedFrom('authService.currentUser.id', 'getResourceForType', 'isOfTypeBorrowing', 'isOfTypeBorrowingReturn')
  private get canUpdate() {
    return this.authService.checkAccess(this.getResourceForType, Actions.Update)
      && this.hasBorrowingAccess(Actions.Update);
  }

  @computedFrom('workOrder.workOrderTypeId')
  public get getResourceForType(): string {
    return WorkOrderUtils.getResourceForType(this.workOrder);
  }

  @computedFrom('workOrder.workOrderStatusId', 'canUpdate')
  public get isWorkOrderModifiable(): boolean {
    return this.workOrder.workOrderStatusId == Constants.WorkOrderStatus.InConstruction && this.canUpdate;
  }

  @computedFrom('workOrder.workOrderTypeId')
  public get isOfTypeInput(): boolean {
    return this.workOrder.workOrderTypeId == Constants.WorkOrderType.Input
  }

  @computedFrom('workOrder.workOrderTypeId')
  public get isOfTypePicking(): boolean {
    return this.workOrder.workOrderTypeId == Constants.WorkOrderType.Picking
  }

  @computedFrom('workOrder.workOrderStatusId', 'getResourceForType', 'isOfTypeBorrowing', 'isOfTypeBorrowingReturn')
  public get articleCancellable(): boolean {
    return (this.workOrder.workOrderStatusId == Constants.WorkOrderStatus.ReadyToBeProcessed ||
      this.workOrder.workOrderStatusId == Constants.WorkOrderStatus.PartiallyProcessed)
      && this.authService.checkPermissonAccess({ resource: this.getResourceForType, action: Actions.Delete })
      && this.hasBorrowingAccess(Actions.Delete);
  }

  @computedFrom('canUpdate, workOrder.workOrderStatusId')
  private get articleEditable() {
    return this.canUpdate
      && (this.workOrder.workOrderStatusId == Constants.WorkOrderStatus.InConstruction ||
        this.workOrder.workOrderStatusId == Constants.WorkOrderStatus.PartiallyProcessed);
  }

  private getRowClass(articleToAction: Zeus.Web.Model.ArticleToAction) {
    return articleToAction.hasBeenCancelled ? (articleToAction.reallyActionQuantity > 0 ? 'work-order-line-partially-cancelled' : 'work-order-line-cancelled') : '';
  }

  private filterLines(record: Zeus.Web.Model.LocationForInputPicking) {
    return record.reallyActionQuantity != 0;
  }

  @computedFrom('workOrder.workOrderStatusId', 'articleToActions.length', 'cancelledNumber')
  public get isAnyLineShorted(): boolean {
    return this.articleToActions?.some(x => x.shortedActionQuantity > 0);
  }

  private pickingActionQuantityInStockCount(articleToAction: Zeus.Web.Model.ArticleToAction, quantity: number): string {
    if (quantity > 0) {
      let usedForStockCount = articleToAction.article.unitOfMeasures.find(uom => uom.usedForStockCount);
      if (usedForStockCount != null && usedForStockCount.unitOfMeasureId !== articleToAction.unitOfMeasureId) {
        let articleUOM = articleToAction.article.unitOfMeasures.find(uom => uom.unitOfMeasureId == articleToAction.unitOfMeasureId);
        return `(${quantity * articleUOM.quantityInStockCountUOM}x ${usedForStockCount.unitOfMeasure.denomination._translation})`;
      }
    }
    return '';
  }

  private async openAddArticle() {
    if (this.workOrder.id <= 0) {
      let saveOk = await this.saveWorkOrder();
      if (saveOk == false) {
        return;
      }
    }
    let pushToActions = (articleToAction: Zeus.Web.Model.ArticleToAction) => this.articleToActions.push(articleToAction);

    this.box.showFloatingBox(new InputPickFloatingBox(this.workOrder, pushToActions)).whenClosed(response => {
      if (false == response.wasCancelled && response.output != null) {
        pushToActions(response.output);
        UIInternal.broadcast(Constants.UIInternal.EVT_UI_TABLE_PAGINATION_RELOAD);
      }
    });
  }

  private articleIsEditable(articleToAction: Zeus.Web.Model.ArticleToAction) {
    return this.articleEditable && true !== articleToAction?.hasBeenCancelled;
  }

  private async openEditArticle(articleToAction: Zeus.Web.Model.ArticleToAction) {
    let pushOrSplice = (savedAta: Zeus.Web.Model.ArticleToAction) => {
      if (savedAta.id == articleToAction.id) {
        this.articleToActions.splice(this.articleToActions.findIndex(x => x.id == articleToAction.id), 1, savedAta);
      } else {
        this.articleToActions.push(savedAta);
      }
    };

    let isArticleEditable = this.articleIsEditable(articleToAction);
    let readOnlyMode: InputPickFloatingBoxViewMode = false == isArticleEditable ? 'read' : (this.isWorkOrderModifiable ? 'update' : 'read-quantity');

    this.box.showFloatingBox(new InputPickFloatingBox(this.workOrder, pushOrSplice, articleToAction, readOnlyMode)).whenClosed(response => {
      if (isArticleEditable) {
        if (false == response.wasCancelled && response.output != null) {
          pushOrSplice(response.output);
        } else {
          // Cancelled, reset orginal values
          pushOrSplice(articleToAction);
        }
      }
    });
  }

  private async removeArticleToAction(articleToAction: Zeus.Web.Model.ArticleToAction) {
    let manuallyBlockedModalResult = await WorkOrderUtils.deleteOrCancelArticleToAction([articleToAction]);
    if (manuallyBlockedModalResult.continue) {
      let index = this.articleToActions.findIndex(x => x.id == articleToAction.id);
      this.articleToActions.splice(index, 1);
      this.articleToActionService.deleteEntities([articleToAction], true, null, manuallyBlockedModalResult.modalShown)
        // If delete failes, splice articleToAction back
        .then((isDeleted) => {
          if (isDeleted == false) {
            this.articleToActions.splice(index, 0, articleToAction)
          } else {
            UIInternal.broadcast(Constants.UIInternal.EVT_UI_TABLE_PAGINATION_RELOAD);
          }
        })
        .catch(() => this.articleToActions.splice(index, 0, articleToAction));
    }
  }

  private async cancelArticleToAction(articleToAction: Zeus.Web.Model.ArticleToAction) {
    await WorkOrderUtils.cancelArticleToAction(this.articleToActionService, [articleToAction]);
    this.cancelledNumber++;
  }

  @computedFrom('appModuleService')
  private get showCarrier(): boolean {
    return this.appModuleService.isActive(AppModuleEnum.Conveyor) || this.appModuleService.isActive(AppModuleEnum.CartReception);
  }

  private async loadCarrier(articleToActions: Zeus.Web.Model.ArticleToAction[] = null) {
    articleToActions ??= this.articleToActions;
    if (!this.showCarrier || !this.isOfTypeInput || articleToActions.length == 0) {
      return;
    }

    const result = await this.httpClient.post(Endpoints.Reception.GetBarcodeByLocationToInputPerArticleToInput, JSON.stringify(articleToActions.map(x => x.id)))
    if (result.ok) {
      let barcodes: Map<number, { barcode: string, carrierType: string }> = await result.json();
      let lfips = articleToActions.flatMap(x => x.locationForInputPickings);
      barcodes.forEach((val, key) => {
        let lfip = lfips.find(x => x.id == key);
        (lfip as Zeus.Web.Model.LocationForInput).carrier = val.barcode;
        (lfip as Zeus.Web.Model.LocationForInput).carrierType = val.carrierType;
      });
    }
  }

  protected selectIconForLocation(carrierType: string) {
    switch (carrierType) {
      case Constants.CarrierType.WorkstationPositionAssignment:
        return 'ze-cart-reception';
      case Constants.CarrierType.PalletAssignment:
        return 'ze-pallet';
      default:
        return 'ze-delivery-box'
    }
  }

  //#region Borrowing
  @computedFrom('workOrder.orderSubtypeId')
  public get isOfTypeBorrowing(): boolean {
    return this.workOrder.orderSubtypeId == BConstants.OrderSubtype.Borrowing;
  }
  @computedFrom('workOrder.orderSubtypeId')
  public get isOfTypeBorrowingReturn(): boolean {
    return this.workOrder.orderSubtypeId == BConstants.OrderSubtype.BorrowingReturn;
  }

  private hasBorrowingAccess(operation: string) {
    if (this.appModuleService.isActive(AppModuleEnum.Borrowing) && (this.isOfTypeBorrowing || this.isOfTypeBorrowingReturn)) {
      return this.authService.checkAccess(BConstants.Permissions.WorkOrderBorrowing_AsResource, operation);
    }
    return true;
  }
  //#endregion
}
