import { MultipleBayRoot } from './multiple-bay/multiple-bay-root';
import { AppModuleEnum } from './constants';
import { DropToLightRoot } from './drop-to-light/drop-to-light-root';
import { AutoLaunchRoot } from './auto-launch/auto-launch-root';
import { LabelPrintingRoot } from './label-printing/label-printing-root';
import { RestrictedTraysRoot } from './restricted-trays/restricted-trays-root';
import { StandardTrayTemplatesRoot } from './standard-tray-templates/standard-tray-templates-root';
import { BadgeLoginRoot } from './badge-login/badge-login-root';
import { OrderScanningRoot } from './order-scanning/order-scanning-root';
import { GraphicalLedBarRoot } from './graphical-led-bar/graphical-led-bar-root';
import { MirroringRoot } from './mirroring/mirroring-root';
import { MobileScreensRoot } from './mobile-screens/mobile-screens-root';
import { CartReceptionRoot } from './cart-reception/cart-reception-root';
import { AppModuleProvider } from './app-module-provider';
import { StaticLikeAModulaRoot } from './static-like-a-modula/static-like-a-modula-root';
import { CheckScanningRoot } from './check-scanning/check-scanning-root';
import { ConveyorRoot } from './conveyor/conveyor-root';
import { BorrowingRoot } from './borrowing/borrowing-root';
import { FreePickingRoot } from './free-picking/free-picking-root';
import { ReportsAndKpiRoot } from './reports-and-kpi/reports-and-kpi-root';

export class ZeusAppModuleProvider extends AppModuleProvider {

  constructor() {
    super();
    this.register(AppModuleEnum.DropToLight, new DropToLightRoot());
    this.register(AppModuleEnum.AutoLaunch, new AutoLaunchRoot());
    this.register(AppModuleEnum.MultipleBay, new MultipleBayRoot());
    this.register(AppModuleEnum.LabelPrinting, new LabelPrintingRoot());
    this.register(AppModuleEnum.RestrictedTrays, new RestrictedTraysRoot());
    this.register(AppModuleEnum.StandardTrayTemplates, new StandardTrayTemplatesRoot());
    this.register(AppModuleEnum.BadgeLogin, new BadgeLoginRoot());
    this.register(AppModuleEnum.OrderScanning, new OrderScanningRoot());
    this.register(AppModuleEnum.GraphicalLedBar, new GraphicalLedBarRoot());
    this.register(AppModuleEnum.Mirroring, new MirroringRoot());
    this.register(AppModuleEnum.MobileScreens, new MobileScreensRoot());
    this.register(AppModuleEnum.CartReception, new CartReceptionRoot());
    this.register(AppModuleEnum.StaticLikeAModula, new StaticLikeAModulaRoot());
    this.register(AppModuleEnum.CheckScanning, new CheckScanningRoot());
    this.register(AppModuleEnum.Conveyor, new ConveyorRoot());
    this.register(AppModuleEnum.Borrowing, new BorrowingRoot());
    this.register(AppModuleEnum.FreePicking, new FreePickingRoot());
    this.register(AppModuleEnum.ReportsAndKpi, new ReportsAndKpiRoot());
  }
}
